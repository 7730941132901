import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import "./RangePicker.css";
import { twMerge } from "tailwind-merge";

export interface RangePickerValue {
  min: number;
  max: number;
}

export interface Props {
  size?: number;
  suffix?: string;
  prefix?: (val: number) => string;
  labelClassName?: string;
  step?: number;
  min?: number;
  value: RangePickerValue;
  labelOnHover?: boolean;
  onChange?: (value: RangePickerValue) => void;
  dark?: boolean;
  light?: boolean;
}

export const RangePicker: FunctionComponent<Props &
  Omit<React.HTMLAttributes<HTMLDivElement>, "onChange" | "prefix">> = ({
  size = 20,
  suffix,
  prefix,
  labelClassName,
  step = 1,
  min = 0,
  labelOnHover,
  value,
  onChange,
  dark,
  light,
  ...props
}) => {
  const max = size + min;

  const progressWidth = ((value.max - min - (value.min - min)) / size) * 100;
  const firstPercent = (value.min - min) / size; // RENAME
  const secondPercent = (value.max - min) / size; // RENAME
  const firstThumbPos = `calc(${firstPercent * 100}% - ${16 * firstPercent}px)`;
  const secondThumbPos = `calc(${secondPercent * 100}% - ${16 * secondPercent}px)`;

  const wrapperClasses = classNames("", {
    dark: dark,
  });
  const sliderClasses = classNames("h-[5px] relative bg-background-bright rounded", {
    "dark:bg-background-dark": !light,
  });
  const progressClasses = classNames("h-full left-1/4 right-1/4 absolute bg-primary-lighter rounded", {
    "dark:bg-primary-medium": !light,
  });
  const thumbClasses = classNames("absolute w-4 h-4 bg-primary-lighter -top-2.5 rounded-full z-10", {
    "dark:bg-primary-medium": !light,
  });
  const labelClasses = twMerge(
    classNames(
      "w-max bg-primary-lighter text-primary-darker text-center px-2 py-1 top-4 rounded text-xs absolute z-10 after:content-[' '] after:absolute after:-top-1/3 after:left-1/2 after:-ml-[5px] after:border-4 after:border-solid after:border-transparent after:border-b-primary-lighter",
      {
        "invisible group-hover:visible": labelOnHover,
        visible: !labelOnHover,
        "dark:bg-primary-medium dark:after:border-b-primary-medium": !light,
        [`${labelClassName}`]: labelClassName,
      }
    )
  );

  return (
    <div {...props} className={wrapperClasses}>
      <div className={sliderClasses}>
        <div
          className={progressClasses}
          style={{
            left: `${firstThumbPos}`,
            width: `calc(${progressWidth}% + ${16 * firstPercent}px + ${16 * ((size - value.max) / size)}px)`,
          }}
        />
      </div>
      <div className="range-input relative">
        <div className="group">
          <input
            type="range"
            onChange={(e) => {
              if (parseInt(e.currentTarget.value) < max) {
                onChange?.({ max: value.max, min: parseInt(e.currentTarget.value) });
              }
              if (parseInt(e.currentTarget.value) >= value.max && value.max < max)
                onChange?.({ max: parseInt(e.currentTarget.value) + step, min: value.min });
            }}
            min={min}
            step={step}
            max={max}
            value={value.min}
          />
          <span className={thumbClasses} style={{ left: firstThumbPos }}></span>
          <span className={labelClasses} style={{ left: firstThumbPos, transform: `translateX(calc(-50% + 8px))` }}>
            {prefix && prefix(value.min)}
            {value.min} {suffix}
          </span>
        </div>
        <div className="group">
          <input
            type="range"
            onChange={(e) => {
              if (parseInt(e.currentTarget.value) > min) {
                onChange?.({ max: parseInt(e.currentTarget.value), min: value.min });
              }
              if (parseInt(e.currentTarget.value) <= value.min && value.min > min)
                onChange?.({ max: value.max, min: parseInt(e.currentTarget.value) - step });
            }}
            min={min}
            step={step}
            max={max}
            value={value.max}
          />
          <span className={thumbClasses} style={{ left: secondThumbPos }}></span>
          <span className={labelClasses} style={{ left: secondThumbPos, transform: `translateX(calc(-50% + 8px))` }}>
            {prefix && prefix(value.max)}
            {value.max} {suffix}
          </span>
        </div>
      </div>
    </div>
  );
};
