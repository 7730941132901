import React, { FunctionComponent, Fragment, useEffect } from "react";
import JobOffersPage from "../pages/jobOffers";
import NotFoundPage from "../pages/notfound";
import store, { Stored } from "../services/store";
import { useState } from "react";
import LoginPage from "../pages/login";
import Layout from "./layout";
import JobOfferPage from "../pages/jobOffer";
import { Route, Routes } from "react-router-dom";
import { UserResponse } from "../models/users/users";
import SettingsCustomers from "../pages/settings/customers";
import SettingsUsers from "../pages/settings/users";
import CandidatesPage from "../pages/candidates";
import SettingsBusinessUnits from "../pages/settings/businessUnits";
import DashboardPage from "../pages/dashboard";
import LoaderPage from "../pages/loader";
import { getMe } from "../models/users/users";
import UserAccount from "../pages/account";
import BillingCustomersPage from "../pages/billing/customers";
import BillingCustomerPage from "../pages/billing/customer";
import BillingContractsPage from "../pages/billing/contracts";
import BillingContractPage from "../pages/billing/contract";
import BillingProjectsPage from "../pages/billing/projects";
import BillingDashboardPage from "../pages/billing/dashboard";
import BillingBillsPage from "../pages/billing/bills";
import SettingsRoles from "../pages/settings/roles";
import SettingsDashboards from "../pages/settings/dashboards";
import { SignalsPage } from "../pages/signals";
import { Toaster } from "react-hot-toast";
import SupersetDashboardPage from "../pages/dashboard/superset";

import { tracker } from "..";

const App: FunctionComponent = () => {
  const [token, setToken] = useState<string | undefined>(store.state.RefreshToken);
  const [user, setUser] = useState<UserResponse | undefined>(undefined);

  const fetchUser = async () => {
    const user = await getMe();

    tracker.setUserID(user.email);

    store.update(Stored.User, user);
    setUser(user);
  };

  useEffect(() => {
    if (token) fetchUser();
  }, [!token]);

  store.listen(Stored.RefreshToken, setToken);

  return (
    <Fragment>
      {!token ? (
        <LoginPage />
      ) : !user ? (
        <LoaderPage />
      ) : (
        <Layout>
          {/* Used for ghost login */}
          <div id="sweeftRefreshToken" style={{ display: "none" }}>
            {token}
          </div>
          <Routes>
            <Route path="/" element={<JobOffersPage />} />
            <Route path="/jobs/archived" element={<JobOffersPage archived />} />
            <Route path="/jobs/:id" element={<JobOfferPage />} />
            <Route path="/jobs" element={<JobOffersPage />} />
            <Route path="/candidates" element={<CandidatesPage />} />
            <Route path="/dashboard/:slug" element={<DashboardPage />} />
            <Route path="/superset/" element={<SupersetDashboardPage />} />
            <Route path="/billing/dashboard" element={<BillingDashboardPage />} />
            <Route path="/billing/customers" element={<BillingCustomersPage />} />
            <Route path="/billing/customers/:id" element={<BillingCustomerPage />} />
            <Route path="/billing/customers/:id/:objectType" element={<BillingCustomerPage />} />
            <Route path="/billing/customers/:id/:objectType/:objectId" element={<BillingCustomerPage />} />
            <Route path="/billing/contracts" element={<BillingContractsPage />} />
            <Route path="/billing/contracts/:id" element={<BillingContractPage />} />
            <Route path="/billing/contracts/:id/:objectType" element={<BillingContractPage />} />
            <Route path="/billing/contracts/:id/:objectType/:objectId" element={<BillingContractPage />} />
            <Route path="/billing/projects" element={<BillingProjectsPage />} />
            <Route path="/billing/projects/:id" element={<BillingProjectsPage />} />
            <Route path="/billing/bills" element={<BillingBillsPage />} />
            <Route path="/settings/businessUnits" element={<SettingsBusinessUnits />} />
            <Route path="/settings/customers" element={<SettingsCustomers />} />
            <Route path="/settings/users" element={<SettingsUsers />} />
            <Route path="/settings/roles" element={<SettingsRoles />} />
            <Route path="/settings/dashboards" element={<SettingsDashboards />} />
            <Route path="/account" element={<UserAccount />} />
            <Route path="/signals" element={<SignalsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      )}
      <Toaster />
    </Fragment>
  );
};

export default App;
