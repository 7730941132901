import React, { FunctionComponent } from "react";
import { Paginated, UUID } from "../../models/common";
import { useState, useEffect } from "react";
import {
  CandidateSearchQuery,
  searchCandidates as searchCandidatesReq,
  SearchCandidateResponse,
} from "../../models/candidate";
import CandidateProfile from "../../components/candidateProfile";
import store, { Message } from "../../services/store";
import dayjs from "dayjs";
import CandidatesFilter, { CandidateFilters } from "./filters";
import {
  deleteSavedQuery as deleteSavedQueryRoute,
  getMySettings,
  saveQuery as saveQueryRoute,
} from "../../models/users/settings";
import { Button, Table, ColumnProps, Tooltip, Pagination, PopOver, PopConfirm } from "@getprorecrutement/getpro-design";
import { ArrowSmallLeftIcon, BookmarkIcon, ChevronDownIcon, TrashIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { cleanObj } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import qs from "qs";
import { CandidateInformations } from "../jobOffer/jobApplications/candidateInformations";
import { expFromFirstJobAt } from "../../utils/formatters";
import CandidatesActions from "./actions";

const PER_PAGE = 10;

// const columns: ColumnProps<Candidate>[] = [
//   {
//     title: "",
//     width: 30,
//     dataIndex: "linkedin_url",
//     key: "linkedin_url",
//     render: (linkedinUrl) => (
//       <div>
//         {linkedinUrl && (
//           <a onClick={(e) => e.stopPropagation()} href={linkedinUrl} target="_blank" rel="noreferrer">
//             <LinkedinIcon />
//           </a>
//         )}
//       </div>
//     ),
//   },
//   {
//     title: "Nom",
//     dataIndex: "full_name",
//     key: "full_name",
//     // filterBy: "text",
//     width: 250,
//     render: (fullName) => <div className="max-w-[240px] truncate">{fullName || "Candidat anonyme"}</div>,
//   },
//   {
//     title: "Adresse e-mail",
//     dataIndex: "email",
//     key: "email",
//     // filterBy: "text",
//   },
//   {
//     title: "Téléphone",
//     dataIndex: "phone",
//     key: "phone",
//     // filterBy: "text",
//     render: (phone) => (
//       <Tooltip light position="top" title={phone}>
//         {phone}
//       </Tooltip>
//     ),
//   },
//   {
//     title: "Date de mise a jour",
//     dataIndex: "updated_at",
//     key: "updated_at",
//     render: (updated_at) => {
//       const displayed = dayjs(updated_at).format("LL");
//       return (
//         <Tooltip light position="top" title={displayed}>
//           {displayed}
//         </Tooltip>
//       );
//     },
//   },
// ];

const newColumns: ColumnProps<SearchCandidateResponse>[] = [
  {
    title: "Candidat",
    dataIndex: "id",
    key: "id",
    render: (_: UUID, record: SearchCandidateResponse) => <CandidateInformations candidate={record} />,
  },
  {
    title: "Titre de poste",
    dataIndex: "job_title",
    key: "job_title",
    render: (title?: string) => (
      <>
        {title && (
          <div className="px-2 rounded-full bg-primary-bright text-primary-regular w-fit font-bold text-[10px] truncate max-w-full">
            {title}
          </div>
        )}
      </>
    ),
  },
  {
    title: "Compétences",
    dataIndex: "skills",
    key: "skills",
    render: (skills: string[]) => (
      <div className="flex items-center flex-wrap gap-2 relative max-w-[calc(100%-18px)]">
        {skills.slice(0, 2).map((s, i) => (
          <div
            className="px-2 rounded-full bg-primary-bright text-primary-regular w-fit font-bold text-[10px] max-w-full relative"
            key={`skill-${i}`}
          >
            <div className="truncate">{s}</div>
            {skills.length > 2 && i === 1 && (
              <div className="text-primary-medium font-bold text-[10px] absolute left-full translate-x-1/2 top-0">
                <Tooltip
                  position="bottom-left"
                  customRenderer={() => (
                    <div className="flex flex-col gap-1 text-primary-medium font-bold text-[10px] max-w-[250px]">
                      {skills.slice(2).map((e, i) => (
                        <div className="truncate" key={`skill-${2 + i}`}>
                          {e}
                        </div>
                      ))}
                    </div>
                  )}
                >
                  +{skills.length - 2}
                </Tooltip>
              </div>
            )}
          </div>
        ))}
      </div>
    ),
  },
  {
    width: 80,
    title: "Expérience",
    dataIndex: "first_job_at",
    key: "first_job_at",
    render: (firstJobAt?: string) => <div>{firstJobAt && expFromFirstJobAt(firstJobAt)}</div>,
  },
  {
    title: "Mise à jour",
    dataIndex: "updated_at",
    key: "updated_at",
    width: 110,
    render: (updated_at) => {
      const displayed = dayjs(updated_at).format("ll");
      return <div>{displayed}</div>;
    },
  },
];

export const searchQueryValid = (query: CandidateSearchQuery): boolean => {
  if (
    (query.name?.length || 0) > 3 ||
    (query.email?.length || 0) > 3 ||
    (query.phone?.length || 0) > 3 ||
    query.job_offers?.length ||
    query.statuses?.length ||
    query.call_statuses?.length ||
    query.with_email ||
    query.with_phone ||
    query.with_consentment ||
    query.users?.length ||
    query.customers?.length ||
    query.categories?.length ||
    query.skills?.length ||
    query.job_titles?.length ||
    query.id ||
    query.experience ||
    query.location
  )
    return true;
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepCompare = (query: any, searchParams: any): boolean => {
  if (!query || !searchParams) return false;
  if (Object.keys(query).length !== Object.keys(searchParams).length) return false;
  for (const [key, value] of Object.entries(query)) {
    if (typeof value === "object") {
      if (!deepCompare(value, searchParams[key])) return false;
    } else if (value !== searchParams[key]) {
      return false;
    }
  }
  return true;
};

const parseQsExperience = (value: { min: string; max: string }): { min: number; max: number } => {
  return { min: parseInt(value.min), max: parseInt(value.max) };
};

const parseQsLocation = (value: {
  location_id: string;
  lat: string;
  lon: string;
}): { location_id: number; lat: string; lon: string } => {
  return {
    location_id: parseInt(value.location_id),
    lat: value.lat,
    lon: value.lon,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseQsQuery = (query: any): CandidateSearchQuery => {
  const newQuery = query;
  for (const [key, value] of Object.entries(query)) {
    if (value === "true" || value === "false") newQuery[key] = value === "true";
    else if (key === "page" || key === "per_page") newQuery[key] = parseInt(value as string);
    else if (key === "experience") newQuery[key] = parseQsExperience(value as { min: string; max: string });
    else if (key === "location")
      newQuery[key] = parseQsLocation(
        value as {
          location_id: string;
          lat: string;
          lon: string;
        }
      );
    else newQuery[key] = value;
  }
  return newQuery;
};

const compareQueryWithSearch = (query: CandidateSearchQuery, search: string): boolean => {
  return deepCompare(
    query,
    parseQsQuery(
      qs.parse(search, {
        ignoreQueryPrefix: true,
      }) as CandidateSearchQuery
    )
  );
};

const comparePathWithLocation = (path: string, search: string): boolean => {
  return deepCompare(
    parseQsQuery(
      qs.parse(path, {
        ignoreQueryPrefix: true,
      }) as CandidateSearchQuery
    ),
    cleanObj({
      ...parseQsQuery(
        qs.parse(search, {
          ignoreQueryPrefix: true,
        }) as CandidateSearchQuery
      ),
      page: undefined,
      per_page: undefined,
      id: undefined,
    })
  );
};

export const CandidatesPage: FunctionComponent = () => {
  const location = useLocation();
  const [candidates, setCandidates] = useState<Paginated<SearchCandidateResponse> | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [query, _setQuery] = useState<CandidateSearchQuery>({
    no_process: true,
    selected_filters: [CandidateFilters.WithNoProcess],
  });
  const [selectedCandidateId, setSelectedCandidateId] = useState<UUID | undefined>();
  const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout> | undefined>();
  const [selection, setSelection] = useState<UUID[]>([]);
  const [savedQueries, setSavedQueries] = useState<
    {
      id: string;
      name: string;
      path: string;
    }[]
  >([]);
  const [currentSavedQuery, setCurrentSavedQuery] = useState<{
    id: string;
    name: string;
    path: string;
  }>();
  const [routingTimeout, setRoutingTimeout] = useState<ReturnType<typeof setTimeout> | undefined>();

  const navigate = useNavigate();

  const setQuery = (query: CandidateSearchQuery) => {
    setSelection([]);
    _setQuery((q) => {
      const saveQuery = {
        ...q,
        // Reset pagination on search
        page: undefined,
        ...query,
      };
      return cleanObj({
        ...saveQuery,
        // Remove include_terminated when job_offers are empty
        include_terminated: saveQuery.job_offers?.length ? saveQuery.include_terminated : undefined,
      });
    });
  };

  useEffect(() => {
    if (!query.id && selectedCandidateId) setSelectedCandidateId(undefined);
    if (routingTimeout) clearTimeout(routingTimeout);
    setRoutingTimeout(setTimeout(setQueryParam, 300));
  }, [query]);

  const setQueryParam = () => {
    const queryParams = Object.entries(query).reduce((acc, [key, value]) => {
      if (!(Array.isArray(value) && !value.length)) acc[key] = value;

      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);

    if (!compareQueryWithSearch(queryParams, location.search)) {
      navigate({
        pathname: "/candidates",
        search: qs.stringify(queryParams),
      });
    }
  };

  useEffect(() => {
    const current = savedQueries.find((sq) => comparePathWithLocation(sq.path, location.search));
    if (current) setSelection([]);
    setCurrentSavedQuery(current);
  }, [location.search, savedQueries]);

  useEffect(() => {
    // Run filters on change url
    const newQuery = parseQsQuery(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }) as CandidateSearchQuery
    );
    if (searchQueryValid(newQuery)) searchCandidates(newQuery);
    else setCandidates({ data: [], total: 0 });

    if (!deepCompare(query, newQuery) && location.search) _setQuery(newQuery);
  }, [location.search]);

  useEffect(() => {
    getSavedQueries();
  }, []);

  const getSavedQueries = async () => {
    const settings = await getMySettings();
    setSavedQueries(
      (settings.saved_candidate_queries || []).map((e) => {
        const path = qs.stringify(cleanObj(e.query));
        return {
          id: e.id,
          name: e.name,
          path,
        };
      })
    );
  };

  const saveQuery = async (name: string) => {
    if (!savedQueries.map((sq) => sq.name).includes(name)) {
      await saveQueryRoute({ name, query: { ...query, page: undefined, per_page: undefined, id: undefined } });
      await getSavedQueries();

      store.notify(Message.RefreshSettings);
      toast.success("Recherche enregistrée avec succès");
    } else {
      toast.error("Une recherche est déjà enregistrée avec ce nom");
    }
  };

  const deleteSavedQuery = async (id: UUID) => {
    await deleteSavedQueryRoute(id);
    await getSavedQueries();
    if (currentSavedQuery?.id === id) _setQuery({});

    store.notify(Message.RefreshSettings);
    toast.success("Vue supprimée avec succès");
  };

  const searchCandidates = async (query: CandidateSearchQuery): Promise<void> => {
    setLoading(true);
    if (searchTimeout) clearTimeout(searchTimeout);

    setSearchTimeout(
      setTimeout(async () => {
        const res = await searchCandidatesReq(
          {
            page: !query.id && query.page ? query.page : 1,
            per_page: query.per_page || PER_PAGE,
          },
          query || {}
        ).finally(() => setLoading(false));

        setCandidates(res);

        // Auto open single candidate when querying by id
        if (query.id && res?.data?.length === 1) {
          setSelectedCandidateId(res.data[0].id);
        }
      }, 500)
    );
  };

  const onUpdateSelect = (val: SearchCandidateResponse) => {
    setSelection((s) => (s.includes(val.id) ? s.filter((e) => e !== val.id) : [...s, val.id]));
  };

  const onSelectAll = (select: boolean) => {
    const currentList = (candidates?.data || []).map((c) => c.id);
    setSelection((s) => (select ? [...s, ...currentList] : s.filter((e) => !currentList.includes(e))));
  };

  const pageSelection = () => {
    return (candidates?.data || []).reduce((acc, next) => {
      if (selection.includes(next.id)) acc.push(next.id);
      return acc;
    }, [] as UUID[]);
  };

  return (
    <div className="bg-white">
      <div className="px-8 pt-8 pb-2 bg-inherit">
        <div className="flex items-center justify-between">
          <div className="text-3xl font-bold">Candidats</div>
          {savedQueries.length > 0 && (
            <PopOver
              position="bottom-left"
              content={
                <div className="flex-col gap-2 min-w-[140px] w-fit">
                  {savedQueries.map((sq) => (
                    <div
                      key={sq.id}
                      className="flex items-center gap-2 justify-between cursor-pointer text-content-light hover:text-content-darker group text-sm"
                      onClick={() =>
                        navigate({
                          pathname: "/candidates",
                          search: sq.path,
                        })
                      }
                    >
                      <div className="whitespace-nowrap">{sq.name}</div>

                      <PopConfirm
                        position="bottom-left"
                        title={`Êtes-vous sûr de vouloir supprimer la recherche "${sq.name}" ?`}
                        onValidate={async () => await deleteSavedQuery(sq.id)}
                        light
                      >
                        <TrashIcon
                          width={16}
                          height={16}
                          className={"text-error-normal invisible group-hover:visible"}
                        />
                      </PopConfirm>
                    </div>
                  ))}
                </div>
              }
            >
              <div className="bg-content-darker text-xs text-white rounded-full flex items-center gap-2.5 w-fit pl-4 pr-3 py-2">
                <BookmarkIcon width={16} height={16} className="stroke-2" />
                <div className="font-medium">Mes recherches</div>
                <ChevronDownIcon width={16} height={16} className="stroke-2" />
              </div>
            </PopOver>
          )}
        </div>
        <CandidatesFilter
          query={query}
          setQuery={setQuery}
          saveQuery={saveQuery}
          currentSavedQuery={currentSavedQuery}
          clearQuery={() => {
            setCurrentSavedQuery(undefined);
            _setQuery({});
          }}
        />
        <div className="flex items-center gap-1 text-xs text-content-light font-medium mt-4 h-8">
          {!!candidates?.total && <span>{candidates.total} résultats</span>}
          {!!selection.length && (
            <div className="flex gap-2 items-center">
              <span>({selection.length} sélectionnés)</span>
              <div className="h-6 w-[1px] bg-black" />
              <CandidatesActions selected={selection} query={query} />
            </div>
          )}
        </div>
      </div>
      <div className="px-6 bg-inherit">
        {selectedCandidateId ? (
          <div>
            <Button
              size="small"
              kind="light"
              light
              className="mb-2"
              icon={<ArrowSmallLeftIcon />}
              title="Retour aux candidats"
              onClick={() => _setQuery((q) => ({ ...q, id: undefined }))}
            />
            <CandidateProfile id={selectedCandidateId} />
          </div>
        ) : (
          <div>
            <Table
              light
              bordered
              size="large"
              rowClassName={(r) => `cursor-pointer ${r.blacklisted ? "blacklisted" : ""}`}
              columns={newColumns}
              loading={loading}
              dataSource={candidates || { data: [], total: 0 }}
              selection={{
                selected: pageSelection(),
                onCheck: onUpdateSelect,
                onCheckAll: onSelectAll,
                selectKey: "id",
              }}
              onClick={(record) =>
                record.id &&
                _setQuery((q) => ({
                  ...q,
                  id: record.id,
                }))
              }
            />
            <div className="flex justify-end my-2">
              <Pagination
                light
                pageParamsActions={[
                  { action: () => setQuery({ per_page: undefined }), title: "10 par page" },
                  { action: () => setQuery({ per_page: 25 }), title: "25 par page" },
                  { action: () => setQuery({ per_page: 50 }), title: "50 par page" },
                ]}
                pageSize={query.per_page || PER_PAGE}
                total={candidates?.total || 0}
                page={query.page || 1}
                onChange={(page) => _setQuery((q) => ({ ...q, page: page > 1 ? page : undefined }))}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidatesPage;
