import httpClient from "../services/http/client";
import ROUTES from "../services/http/routes";

export type UUID = string;

export interface Paginated<T> {
  total: number;
  data: T[];
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GeocodedLocation {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: unknown;
  place_id: unknown;
  postcode_localities: string[];
  types: string[];
  street?: string;
  street_number?: string;
  postal_code?: string;
  locality?: string;
  area?: string;
  area_level_1?: string;
  country?: string;
}

export interface NominatimLocationResponse {
  name: string;
  location_id: number;
  country_code?: string;
  postal_code?: string;
  display_name: string;
  lat: string;
  lon: string;
}

export async function geocode(query: string): Promise<GeocodedLocation[]> {
  if (query.length > 3) return httpClient.req(ROUTES.GEOCODE({ query }));
  return Promise.resolve([]);
}

export async function geocodeCity(query: string): Promise<NominatimLocationResponse[]> {
  if (query.length > 3) return httpClient.req(ROUTES.GEOCODE_CITY({ city: query }));
  return Promise.resolve([]);
}

export async function lookupLocation(id: number): Promise<NominatimLocationResponse> {
  return httpClient.req(ROUTES.LOOKUP_LOCATION(id));
}
