import { CheckIcon, ChevronDownIcon, ChevronUpIcon, XIcon, SearchIcon } from "@heroicons/react/outline";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { Tag, TagColor } from "../../../Tag";
import { inputPaddingClasses, labelClasses, textSizeClasses, wrapperInputClasses } from "../../../../utils/common";
import { TextInput } from "../TextInput";
import { SelectBase } from "./base";
import { Spinner } from "../../../Spinner";
export { SelectPortal } from "./portal";

export interface Props<T> {
  size?: "small" | "medium" | "large";
  options: T[];
  optionRender: (item: T) => string | JSX.Element; // USELESS IF USING OPTION RENDER
  getKey: (item: T) => string;
  onChange: (value?: T) => void;
  bordered?: boolean;
  rounded?: boolean;
  width?: number;
  error?: string;
  label?: string;
  disabled?: boolean;
  defaultOpen?: boolean;
  dropdownRender?: (menu: JSX.Element) => JSX.Element;
  dark?: boolean;
  light?: boolean;
  notFoundPlaceholder?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  dropdownClassName?: string;
  loading?: boolean;
  onSearch?: (value: string) => void;
  dropdownOffset?: { x?: number; y?: number };
  showArrowIcon?: boolean;
  valueClassName?: string;
  icon?: JSX.Element;
  optionClassName?: string;
}

export interface SingleValue<T> {
  value?: T;
  customValueRender?: (value: T) => JSX.Element;
  type: "single";
  hideCheckbox?: never;
}

export interface MultipleValues<T> {
  value?: T[];
  customValueRender?: (value: T[]) => JSX.Element;
  type: "multiple";
  hideCheckbox?: boolean;
}

export const Select = <T extends unknown>({
  size = "medium",
  options,
  value,
  optionRender,
  onChange,
  getKey,
  bordered,
  rounded,
  width,
  label,
  type,
  disabled,
  defaultOpen = false,
  dropdownRender,
  onSearch,
  error,
  dark,
  light,
  notFoundPlaceholder = "Aucune option disponible",
  placeholder = "Non défini",
  searchPlaceholder = "Recherche",
  dropdownClassName,
  loading,
  dropdownOffset,
  showArrowIcon,
  valueClassName,
  icon,
  customValueRender,
  optionClassName,
  hideCheckbox,
  ...props
}: Props<T> &
  (SingleValue<T> | MultipleValues<T>) &
  Omit<React.HTMLAttributes<HTMLDivElement>, "onChange" | "rounded">) => {
  const [opened, setOpened] = useState<boolean>(defaultOpen);
  const [search, setSearch] = useState<string>("");

  const isOpened = (): boolean => {
    if (opened && (options.length > 0 || placeholder)) {
      return true;
    } else {
      return false;
    }
  };

  const wrapperClasses = twMerge(
    classNames("relative bg-inherit group/select focus:outline-none flex items-center gap-2", {
      [`w-[${width}px]`]: !!width,
      "w-full": !width,
      "cursor-pointer": !disabled,
      [`${props.className}`]: props.className,
    })
  );

  const notFoundClasses = classNames(
    `bg-inherit text-content-darker flex items-center gap-2 justify-start ${textSizeClasses({
      size,
    })}`,
    {
      "dark:bg-background-dark dark:text-content-bright": !light,
      "px-4 py-1.5": size === "small",
      "px-7 py-2.5": size === "medium",
      "px-8 py-2.5": size === "large",
    }
  );

  const optionClasses = twMerge(
    classNames(`hover:bg-background-lightest cursor-pointer ${notFoundClasses}`, {
      "dark:hover:bg-background-medium": !light,
      [`${optionClassName}`]: optionClassName,
    })
  );

  const searchWrapperClasses = classNames("bg-content-lightest rounded-full px-2 py-1", {
    "dark:bg-background-darker": !light,
  });

  const errorClasses = classNames("mt-1 italic text-error-normal", {
    "pl-3 text-xs": size === "small",
    "pl-5 text-sm": size === "medium",
    "pl-8": size === "large",
  });

  const valueClasses = twMerge(
    classNames(
      `flex items-center justify-between gap-2 w-full bg-inherit transition-colors relative ${textSizeClasses({
        size,
      })} ${inputPaddingClasses({
        size,
        bordered,
      })} ${wrapperInputClasses({
        size,
        disabled,
        light,
        bordered,
        focused: isOpened(),
        error,
      })}`,
      {
        "dark:text-content-bright": !light && !disabled,
        "text-content-darker": !disabled,
        "bg-background-lightest text-content-light": disabled,
        "dark:bg-background-dark dark:text-content-dark": disabled && !light,

        // BORDER
        // border: bordered,
        // "group-hover/select:border-border-dark": bordered && !disabled,
        // "dark:group-hover/select:border-border-lighter": bordered && !light && !disabled,
        // "border-border-dark": bordered && isOpened(),
        // "dark:border-border-lighter": bordered && isOpened() && !light,
        // "border-border-lighter": bordered && !isOpened() && !disabled,
        // "border-border-bright": bordered && disabled,
        // "dark:border-border-medium": bordered && !isOpened() && !light,
        // "rounded-3xl": rounded && size === "small",
        // "rounded-4xl": rounded && size === "medium",
        // "rounded-6xl": rounded && size === "large",
        [`${valueClassName}`]: valueClassName,
      }
    )
  );

  const renderInputClasses = classNames("flex items-center gap-2", {
    "max-w-[calc(100%-22px)]": showArrowIcon,
    "max-w-full": !showArrowIcon,
  });

  const dropDownClasses = twMerge(
    classNames("bg-background-bright overflow-hidden rounded-3xl", {
      "dark:bg-background-dark": !light,
      hidden: !isOpened(),
      [`${dropdownClassName}`]: dropdownClassName,
      "m-0": !opened,
    })
  );

  const selectLabelClasses = labelClasses({ size, disabled, light, error });

  const placeholderClasses = classNames("text-content-light", {
    "dark:text-content-regular": !light,
  });

  const changeValue = (value?: T) => {
    if (type === "single") setOpened(false);

    onChange(value);
  };

  const renderOption = (option: T) => (
    <div key={getKey(option)} className={optionClasses} onClick={() => changeValue(option)}>
      {type === "multiple" && !hideCheckbox && (
        <div
          className={`w-4 h-4 min-w-[16px] border border-solid rounded-sm ${
            value && (value as T[]).map((v) => getKey(v)).includes(getKey(option)) ? "bg-primary-medium" : ""
          }`}
        >
          {value && (value as T[]).map((v) => getKey(v)).includes(getKey(option)) && (
            <CheckIcon className="w-full h-full text-white" />
          )}
        </div>
      )}
      {optionRender(option)}
    </div>
  );

  const renderInput = () => {
    if (type === "multiple" && value && (value as T[]).length > 0)
      return (
        <div>
          {(value as T[]).length} Sélectionné{(value as T[]).length > 1 ? "s" : ""}
        </div>
      );
    else if (type === "single" && value) return optionRender(value as T);
    else return <div className={placeholderClasses}>{placeholder}</div>;
  };

  const renderCustomInput = () => {
    if (
      customValueRender &&
      ((type === "multiple" && value && (value as T[]).length > 0) || (type === "single" && value))
    )
      return customValueRender(value as T & T[]);
    else return <div className={placeholderClasses}>{placeholder}</div>;
  };

  const render = () => {
    if (loading)
      return (
        <div className="h-full w-full flex justify-center items-center py-5">
          <Spinner />
        </div>
      );

    let content = options.length ? (
      <div className="max-h-48 overflow-auto">{options.map((v) => renderOption(v))}</div>
    ) : (
      <div className={notFoundClasses}>{notFoundPlaceholder}</div>
    );
    return dropdownRender ? dropdownRender(content) : content;
  };

  return (
    <div className="Select bg-inherit">
      <div className={`bg-inherit ${dark ? "dark" : ""}`}>
        <div {...props} className={wrapperClasses}>
          {label && <label className={selectLabelClasses}>{label}</label>}
          <SelectBase
            disabled={disabled}
            open={opened}
            setOpen={setOpened}
            render={(id) => (
              <div id={id} className={valueClasses}>
                {customValueRender ? (
                  renderCustomInput()
                ) : (
                  <>
                    <div className={renderInputClasses}>
                      {icon && <div className="w-4 h-4 min-w-[16px]">{icon}</div>}
                      {renderInput()}
                    </div>
                  </>
                )}
                {showArrowIcon && (
                  <ChevronDownIcon
                    width={14}
                    className={`transition-all min-w-[14px] ${isOpened() ? "-rotate-180" : ""}`}
                  />
                )}
              </div>
            )}
            renderDropdown={() => (
              <div className={dark ? "dark" : ""}>
                <div className={dropDownClasses}>
                  {onSearch && (
                    <div className="p-2">
                      <div className={searchWrapperClasses}>
                        <TextInput
                          autoFocus={true}
                          bordered={false}
                          icon={<SearchIcon />}
                          type="text"
                          size="small"
                          light={light}
                          placeholder={searchPlaceholder}
                          value={search}
                          onClick={(ev) => ev.stopPropagation()}
                          onChange={({ target }) => {
                            setSearch(target.value);
                            onSearch(target.value);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {render()}
                </div>
              </div>
            )}
          />
        </div>
        {error && <div className={errorClasses}>{error}</div>}
      </div>
    </div>
  );
};
