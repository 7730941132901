import httpClient from "../services/http/client";
import ROUTES, { PaginationParams } from "../services/http/routes";
import { notifyError } from "../services/requests";
import { NewProject } from "./billing/project";
import { GeocodedLocation, Paginated, UUID } from "./common";
import { Customer } from "./customers";
import { JobApplicationResponse } from "./jobApplications";
import { JobTitle } from "./jobTitle";
import { Skill } from "./skill";
import { UserResponse } from "./users/users";

export const OpportunityTypes = [
  { label: "Cabinet", value: "cabinet" },
  { label: "RPO", value: "rpo" },
  { label: "Interne", value: "interne" },
];

export interface Status {
  id: UUID;
  name: string;
  index: number;
  created_at: string;
  updated_at: string;
  color: string;
}

export interface JobOffersFilters {
  owned_by_me: boolean;
  owned_by_bu: boolean;
}

export interface SummaryStatus extends Status {
  active_count: number;
  terminated_count: number;
  candidates_count: number;
}

export interface Summary {
  statuses: SummaryStatus[];
  active_count: number;
  terminated_count: number;
  candidates_count: number;
}

export enum QuestionType {
  Bool = "bool",
  Lang = "lang",
  Skill = "skill",
  Experience = "experience",
  Salary = "salary",
  Size = "size",
}

export enum ValidationType {
  Bool = "bool",
  Range = "range",
  Minimum = "minimum",
}

export interface RangeValidation {
  type: ValidationType;
  min: number;
  max: number;
}

export interface BoolValidation {
  type: ValidationType;
}

export interface MinimumValidation {
  type: ValidationType;
  value: number;
}

export interface Question extends PublicQuestion {
  validation: BoolValidation | MinimumValidation | RangeValidation;
}

export interface PublicQuestion {
  kind: QuestionType;
  question: string;
  id: string;
  placeholder?: string;
}

export interface JobOfferExperience {
  min: number;
  max?: number;
}

export interface JobOfferSalary {
  min: number;
  max: number;
}

export interface FullJobOffer extends JobOffer {
  summary: Summary;
}

export interface JobOffer extends NewJobOfferDetails {
  id: UUID;
  slug: string;
  created_at: string;
  updated_at: string;
  archived: boolean;
  published: boolean;
  owner: UserResponse;
  company: Customer;
  candidates_count: number;
  email_template_subject?: string;
  email_template_body?: string;
  invoice_amount: number;
  archived_reason?: string;
  questions: Question[];
  archived_at: string;
  job_title?: JobTitle;
  full_skills: Skill[];
}

export interface NewJobOffer {
  job_offer: NewJobOfferDetails;
  project?: NewProject;
}

export interface NewJobOfferDetails {
  name: string;
  company_id: UUID;
  description?: string;
  owner_id: string;
  anonymous_company?: boolean;
  skills: UUID[];
  job_title_id: UUID;
  location: GeocodedLocation;
  opportunity_type: "cabinet" | "rpo" | "interne";
  expected_salary: number;
  new_customer?: boolean;
  exclude_from_stats?: boolean;
  validation_period_months: number;
  business_unit_id: UUID;
  project_id?: UUID;
  experience: JobOfferExperience;
  salary: JobOfferSalary;
}

export interface JobOfferData extends Partial<NewJobOfferDetails> {
  // id: UUID; TO CHECK
  questions?: Question[];
  archived?: boolean;
  archived_reason?: string;
  published_at?: string;
  published?: boolean;
  email_template_subject?: string;
  email_template_body?: string;
}

export async function getJo(id: UUID): Promise<FullJobOffer> {
  return httpClient.req(ROUTES.FETCH_JOB_OFFER(id), (err) =>
    notifyError(err || "Erreur lors de la récupération de l'offre")
  );
}

export async function getJos(
  params: PaginationParams & {
    archived?: boolean;
    owned_by_me?: boolean;
    search?: string;
    bu_id?: UUID;
    owner_ids?: UUID[];
    customer_ids?: UUID[];
    id?: UUID[];
  }
): Promise<Paginated<FullJobOffer>> {
  return httpClient.req(ROUTES.FETCH_JOB_OFFERS(params), (err) =>
    notifyError(err || "Erreur lors de la récupération des offres")
  );
}

export async function getJoJas(
  id: UUID,
  params: PaginationParams & {
    status_ids?: UUID[];
    call_status_ids?: UUID[];
    search?: string;
    terminated?: boolean;
    pinned?: boolean;
    sort_by?: keyof JobApplicationResponse;
    sort_direction?: "asc" | "desc";
  }
): Promise<Paginated<JobApplicationResponse>> {
  return httpClient.req(
    ROUTES.FETCH_JOB_OFFER_JOB_APPLICATIONS(id, params),
    () => "Erreur lors de la récupération des candidatures de l'offre"
  );
}

export async function getCandidateFeedbackTemplate(
  jobAppId: UUID,
  kind: string
): Promise<{ body?: string; subject?: string }> {
  return httpClient.req(ROUTES.FETCH_FEEDBACK_TEMPLATE(jobAppId, kind), notifyError);
}

export async function createJo(new_jo: NewJobOffer, error?: string): Promise<FullJobOffer> {
  return httpClient.req(ROUTES.CREATE_JOB_OFFER(new_jo), (err) =>
    notifyError(error || err || "Erreur lors de la création de l'offre")
  );
}

export async function updateJo(data: JobOfferData & { id: UUID }, error?: string): Promise<FullJobOffer> {
  return httpClient.req(ROUTES.UPDATE_JOB_OFFER(data), (err) =>
    notifyError(error || err || "Erreur lors de la mise à jour de l'offre")
  );
}
