import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import qs from "qs";
import JobOfferForm from "../../components/forms/jobOfferForm";
import { FullJobOffer, getJo, getJoJas, OpportunityTypes, Status } from "../../models/jobOffers";
import { Paginated, UUID } from "../../models/common";
import JobOfferActions from "../../components/jobOfferActions";
import JobApplications from "./jobApplications";
import { Modal, Spinner, Tag, Tooltip, Image, Button } from "@getprorecrutement/getpro-design";
import { createJOGFile, getJOGfiles, GFile, GFileKind } from "../../models/gfiles";
import { ExclamationCircleIcon, EyeSlashIcon, FireIcon } from "@heroicons/react/24/outline";
import { FireIcon as FireIconSolid } from "@heroicons/react/24/solid";
import InlineLabelValue from "../../components/inlineLabelValue";
import { euroDisplay } from "../../utils/formatters";
import md5 from "md5";
import {
  CondensedJobApplication,
  JobApplicationBase,
  JobApplicationData,
  JobApplicationResponse,
  updateJa,
} from "../../models/jobApplications";
import { DEFAULT_PROFILE_PIC } from "../../assets/profilePicFallback";
import { getCallStatuses, getSources, getStatuses } from "../../services/requests";
import store, { Message } from "../../services/store";
import { Candidate } from "../../models/candidate";
import RecruitedJobApp from "./recruitedJobApp";
import { MetaForm, StepComponent, getSteps } from "../../components/forms/metaForm";

export const generateGdriveUrl = (file: GFile) => {
  let url;
  switch (file.kind) {
    case GFileKind.Folder:
      url = `https://drive.google.com/drive/folders/${file.gdrive_id}`;
      break;
    case GFileKind.Kickoff:
      url = `https://docs.google.com/spreadsheets/d/${file.gdrive_id}`;
      break;
    case GFileKind.ScoreCard:
    case GFileKind.Teaser:
      url = `https://docs.google.com/document/d/${file.gdrive_id}`;
      break;
    case GFileKind.Report:
      url = `https://docs.google.com/presentation/d/${file.gdrive_id}`;
      break;
  }
  return url;
};

export const JobOfferPage: FunctionComponent = () => {
  const [jobOffer, setJobOffer] = useState<FullJobOffer | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  // const [salesOwner, setSalesOwner] = useState<UserResponse | undefined>(undefined);
  const [gfilesByKind, setGfiles] = useState<{ [key in GFileKind]?: GFile }>();
  const [editJo, setEditJo] = useState<boolean>(false);
  const [filterByPinned, setFilterByPinned] = useState<boolean>(false);
  const [pinnedJas, setPinnedJas] = useState<Paginated<JobApplicationResponse>>();
  const [recruitedJa, setRecruitedJa] = useState<JobApplicationResponse>();
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [callStatuses, setCallStatuses] = useState<Status[]>([]);
  const [sources, setSources] = useState<{ id: UUID; name: string }[]>([]);
  const [metaFormData, setMetaFormData] = useState<
    | undefined
    | {
        steps: StepComponent[];
        jobApp: JobApplicationBase;
        candidate: Candidate;
        onFinish: () => void;
      }
  >(undefined);

  const { id } = useParams<{ id: string }>() as { id: UUID };

  const location = useLocation();

  const { statusIds, candidateId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as {
    statusIds: string[] | undefined;
    candidateId: string | undefined;
  };

  async function updateStatusId(value: UUID, jobApp: JobApplicationBase, candidate: Candidate) {
    const steps = getSteps(jobApp.status_id, value, statuses);
    if (steps.length) {
      setMetaFormData({
        steps,
        candidate: candidate,
        jobApp: jobApp, // yikes
        onFinish: async () => {
          await updateJaReq({
            id: jobApp.id,
            status_id: value,
          });
        },
      });
    } else {
      await updateJaReq({ status_id: value, id: jobApp.id });
    }
  }

  async function updateJaReq(data: JobApplicationData & { id: UUID }) {
    const ja = await updateJa(data);
    if (ja.status_id === data.status_id && data.status_id === "ce7d87f3-f446-499d-bc49-12112e78f131")
      store.notify(Message.Confetti);
    fetchJobOffer();
  }

  const fetchPinnedJas = async () => {
    const resp = await getJoJas(id, {
      page: 1,
      per_page: 5,
      pinned: true,
    });

    setPinnedJas(resp);
    if (resp.total === 0 && filterByPinned) setFilterByPinned(false);
  };

  const fetchRecruitedJa = async () => {
    const resp = await getJoJas(id, {
      page: 1,
      per_page: 1,
      status_ids: ["ce7d87f3-f446-499d-bc49-12112e78f131", "cedcfdaf-1ee2-458e-b89c-8e19939af2d8"],
      terminated: false,
    });

    if (resp.total > 0 && resp.data.length > 0) {
      setRecruitedJa(resp.data[0]);
    } else {
      setRecruitedJa(undefined);
    }
  };

  const fetchJobOffer = async () => {
    getJo(id).then(setJobOffer);
    await fetchPinnedJas();
    await fetchRecruitedJa();
  };

  const fetchGFiles = async () => {
    getJOGfiles(id).then((gfiles) => {
      const files = gfiles.reduce((acc, f) => ({ ...acc, [f.kind]: f }), {});
      setGfiles(files);
    });
  };

  const fetchMeta = async () => {
    const statuses = await getStatuses();
    setStatuses(statuses.map((v) => ({ ...v, name: v.name.split(" ")[0] })));
    const call_statuses = await getCallStatuses();
    setCallStatuses(call_statuses);
    const sources = await getSources();
    setSources(sources);
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      await fetchMeta();
      const jo = await getJo(id);
      setJobOffer(jo);
      await fetchPinnedJas();
      await fetchRecruitedJa();
      // const sales = await getUser(jo.company.owner_id);
      const gfiles = await getJOGfiles(jo.id);
      // setSalesOwner(sales);
      const files = gfiles.reduce((acc, f) => ({ ...acc, [f.kind]: f }), {});
      setGfiles(files);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAll();
  }, [id]);

  if (!loading && !jobOffer) {
    return (
      <div className="flex flex-col justify-center items-center gap-4 my-20">
        <ExclamationCircleIcon className="w-48 h-48" />
        <div className="text-2xl text-center">L'offre demandée n'as pas été trouvée</div>
        <Link className="mt-4" to="/">
          <Button title="Retour" />
        </Link>
      </div>
    );
  }

  const opportunityType = (
    OpportunityTypes.find((ot) => ot.value === jobOffer?.opportunity_type) || {
      label: "Indéfini",
    }
  ).label;

  const createGFile = async (joId: UUID, kind: GFileKind) => {
    const file = await createJOGFile(joId, kind);
    fetchGFiles();
    window.open(generateGdriveUrl(file), "_blank");
  };

  return (
    <article className="min-h-screen">
      {loading ? (
        <div style={{ width: "100%", padding: "100px 0", textAlign: "center" }}>
          <Spinner size="large" />
        </div>
      ) : (
        <>
          {/* Header */}
          <div className="w-full h-fit pt-4 px-9 relative">
            {jobOffer && (
              <div className="bg-background-bright w-[calc(100%-32px)] h-fit rounded-3xl ml-9 relative py-8 pl-16 pr-6">
                {/* Customer & Owner section */}
                <div className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/2 z-10 bg-background-lightest rounded-full p-1">
                  <div className="w-16 h-16 rounded-full border-[3px] bg-white border-solid border-background-lightest flex items-center justify-center">
                    <img
                      className="max-w-full max-h-full rounded-full"
                      src={`https://storage.googleapis.com/getpro-public/${
                        jobOffer.company.file_id || "e1ca4dfa-7173-40a1-8f9a-917dc3f3556f"
                      }`}
                    />
                  </div>
                  <Tooltip title={jobOffer.owner.full_name} position="bottom" light>
                    <div className="w-16 h-16 rounded-full border-[3px] bg-white border-solid border-background-lightest -mt-4 z-10 relative">
                      <img
                        className="max-w-full max-h-full rounded-full"
                        src={`https://www.gravatar.com/avatar/${md5(jobOffer.owner.email || "")}?d=mp`}
                      />
                    </div>
                  </Tooltip>
                </div>
                {/* Job Offer informations */}
                <div className="text-content-darker">
                  <div className="flex items-center gap-2">
                    <div>{jobOffer.company.name}</div>
                    {jobOffer.anonymous_company && <EyeSlashIcon width={16} height={16} />}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="text-2xl font-bold">{jobOffer.name}</div>
                    {/* TODO FIX TAG WITHOUT BORDER */}
                    <Tag bold light className="bg-teal-200 border-0" size="small" value={opportunityType} />
                  </div>
                  <div className="flex items-center gap-x-16 gap-y-3 mt-4 flex-wrap">
                    <InlineLabelValue
                      direction="vertical"
                      label="Localisation"
                      value={jobOffer.location.formatted_address}
                    />
                    <InlineLabelValue
                      direction="vertical"
                      label="Salaire"
                      value={euroDisplay(jobOffer.expected_salary)}
                    />
                  </div>
                </div>
                {/* Job Offer actions */}
                <div className="absolute top-4 right-4">
                  <JobOfferActions
                    jobOffer={jobOffer}
                    onEdit={() => setEditJo(true)}
                    onUpdate={() => fetchJobOffer()}
                    gfilesByKind={gfilesByKind}
                    createGFile={createGFile}
                  />
                </div>
                {/* Hot candidates */}
                {pinnedJas && pinnedJas.total > 0 && (
                  <div
                    className={`absolute -bottom-6 right-4 bg-background-lightest px-4 py-2 rounded-3xl cursor-pointer flex items-center gap-2 ${
                      filterByPinned ? "text-primary-regular" : "text-content-light hover:text-content-darker"
                    }`}
                    onClick={() => setFilterByPinned((v) => !v)}
                  >
                    {filterByPinned ? <FireIconSolid width={20} height={20} /> : <FireIcon width={20} height={20} />}
                    <div className="flex items-center ml-2">
                      {pinnedJas.data.map((ja) => (
                        <div
                          key={ja.id}
                          className="-ml-2 border-[3px] border-solid border-background-lightest rounded-full"
                        >
                          <Image
                            src={ja.candidate.picture_url || DEFAULT_PROFILE_PIC}
                            fallback={DEFAULT_PROFILE_PIC}
                            className={`rounded-full w-8 h-8`}
                          />
                        </div>
                      ))}
                      {pinnedJas.total > 5 && (
                        <div className="-ml-2 border-[3px] border-solid border-background-lightest rounded-full">
                          <div className="w-8 h-8 flex items-center justify-center bg-content-bright rounded-full font-bold text-content-darker">
                            +{pinnedJas.total - 5}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {recruitedJa && (
              <RecruitedJobApp
                jobApp={recruitedJa}
                statusIds={statusIds}
                statuses={statuses}
                callStatuses={callStatuses}
                sources={sources}
                fetchJo={fetchJobOffer}
                updateStatusId={updateStatusId}
                updateJa={async (data: JobApplicationData) => await updateJaReq({ ...data, id: recruitedJa.id })}
              />
            )}
          </div>

          {jobOffer && (
            <JobApplications
              jobOffer={jobOffer}
              statusIds={statusIds}
              candidateId={candidateId}
              onUpdate={() => fetchJobOffer()}
              pinned={filterByPinned}
              statuses={statuses}
              callStatuses={callStatuses}
              sources={sources}
              updateStatusId={updateStatusId}
              updateJaReq={updateJaReq}
            />
          )}
        </>
      )}
      <Modal light show={editJo} onClose={() => setEditJo(false)} className="w-modal-xl">
        <JobOfferForm
          onSubmit={setJobOffer}
          onFinish={() => {
            setEditJo(false);
            fetchJobOffer();
          }}
          jobOffer={jobOffer}
        />
      </Modal>
      <Modal className="w-modal-lg" light show={!!metaFormData} onClose={() => setMetaFormData(undefined)}>
        {metaFormData && (
          <MetaForm
            key={`metaForm${metaFormData.jobApp.id}`}
            steps={metaFormData.steps}
            jobApp={metaFormData.jobApp as CondensedJobApplication}
            candidate={metaFormData.candidate}
            onFinish={() => {
              metaFormData.onFinish();
              setMetaFormData(undefined);
            }}
            onCancel={() => setMetaFormData(undefined)}
          />
        )}
      </Modal>
    </article>
  );
};

export default JobOfferPage;
