import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { JobOffer, JobOfferData, Question, QuestionType, ValidationType } from "../../../models/jobOffers";
import HtmlEditor from "../inputs/htmlEditor";
import QuestionRow, { getQuestionPreview } from "./questionRow";
import { ProjectData } from "../../../models/billing/project";
import { Divider, Button, MoreActions, TextInput } from "@getprorecrutement/getpro-design";
import { InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { StepHandle } from ".";
import { useFormManager } from "../../../services/form";
import { randomString } from "../../../utils";

interface Props {
  jobOffer: Partial<JobOffer>;
  setDisabled: (value: boolean) => void;
  data: {
    project?: ProjectData;
    job_offer?: JobOfferData;
  };
}

const getBodySize = (val: string | undefined) => {
  if (val != undefined) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(val, "text/html");
    return doc.body.innerText.length;
  } else return 0;
};

export const RedactionStepForm = forwardRef<StepHandle, Props>(function RedactionStepForm(props, ref) {
  const { jobOffer } = props;
  const [emailTemplateLength, setEmailTemplateLength] = useState<number>(getBodySize(jobOffer.email_template_body));

  const form = useFormManager<JobOffer>({
    defaultState: props.data.job_offer || {},
    validations: {
      questions: {
        required: (questions?: Question[]) => {
          if (questions)
            for (const question of questions) {
              const value = question.question
                .replace(getQuestionPreview(question.kind), "")
                .replaceAll("[:value:]", "")
                .replace(" ?", "");
              if (question.kind !== QuestionType.Salary && !value.length) return false;
            }
          return true;
        },
        message: "Merci de préciser la description de la question",
      },
    },
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        isValid() {
          return form.validate();
        },
        getData() {
          return {
            ...props.data,
            job_offer: { ...props.data.job_offer, ...form.state },
          };
        },
      };
    },
    [form.state]
  );

  useEffect(() => {
    props.setDisabled(form.disabled);
  }, [form.disabled]);

  const addQuestion = (question: Question) => {
    form.onChange({ questions: [...(form.state.questions || []), question] });
  };

  const removeQuestion = (index: number) => {
    let newQuestions = (form.state.questions || []).filter((_, i) => i !== index);

    newQuestions = newQuestions.map((q, i) => (i < index ? q : { ...q, index: i - 1 }));

    form.onChange({ questions: newQuestions });
  };

  const updateQuestion = (index: number, question: Question) => {
    const questions = form.state.questions || [];

    form.onChange({ questions: questions.map((q, i) => (i === index ? question : q)) });
  };

  const actions = [
    {
      title: "Langue",
      action: () =>
        addQuestion({
          kind: QuestionType.Lang,
          question: "Quel est votre niveau [:value:]en anglais[:value:] ?",
          validation: { type: ValidationType.Minimum, value: 1 },
          id: randomString(),
        }),
    },
    {
      title: "Compétence",
      action: () =>
        addQuestion({
          kind: QuestionType.Skill,
          question: `Quel est votre niveau de compétence [:value:][:value:] ?`,
          validation: { type: ValidationType.Minimum, value: 1 },
          id: randomString(),
          placeholder: "en React",
        }),
    },
    {
      title: "Expérience",
      action: () =>
        addQuestion({
          kind: QuestionType.Experience,
          question: `Combien d'années d'expérience avez-vous en tant que [:value:]${jobOffer.name}[:value:] ?`,
          validation: { type: ValidationType.Minimum, value: 0 },
          id: randomString(),
        }),
    },
    {
      title: "Salaire",
      action: () =>
        addQuestion({
          kind: QuestionType.Salary,
          question: "Quel est votre salaire actuel en brut annuel ?",
          validation: { type: ValidationType.Range, min: 0, max: 0 },
          id: randomString(),
        }),
    },
    {
      title: "Management",
      action: () =>
        addQuestion({
          kind: QuestionType.Size,
          question: "Quelle taille d'équipe avez-vous été amené à manager [:value:]en direct[:value:] ?",
          validation: { type: ValidationType.Minimum, value: 1 },
          id: randomString(),
        }),
    },
    {
      title: "Question libre",
      action: () =>
        addQuestion({
          kind: QuestionType.Bool,
          question: "",
          validation: { type: ValidationType.Bool },
          id: randomString(),
        }),
    },
  ];

  return (
    <div className="bg-inherit">
      <Divider title="Questionnaire de pré-qualification" light />
      {(form.state.questions || []).length > 0 && (
        <div className="flex flex-col bg-inherit ml-4" style={{ padding: form.state.questions?.length ? 0 : 24 }}>
          {form.state.questions?.map((e, i) => (
            <QuestionRow
              index={i}
              key={e.id}
              question={e}
              updateQuestion={(question) => {
                updateQuestion(i, question);
              }}
              removeQuestion={() => removeQuestion(i)}
              error={form.errors.questions}
            />
          ))}
        </div>
      )}
      <div className="flex justify-end">
        <MoreActions light actions={actions}>
          <Button light title="Nouvelle question" icon={<PlusIcon />} size="small" />
        </MoreActions>
      </div>
      <Divider title="Description complète de l'offre" light />
      <div className="joDescEditor">
        <HtmlEditor
          onChange={(val) => {
            form.onChange({ description: val });
          }}
          content={props.data.job_offer?.description}
          cleanInput
        />
      </div>
      <Divider title="Modèle d'email d'approche" light />
      <TextInput
        light
        type="text"
        onChange={({ target }) => form.onChange({ email_template_subject: target.value })}
        label="Sujet d'email"
        placeholder="[:job_offer_name:]"
        value={form.state.email_template_subject}
      />
      <div className="mt-4">
        <HtmlEditor
          onChange={(val) => {
            form.onChange({ email_template_body: val });
            setEmailTemplateLength(getBodySize(val));
          }}
          cleanInput
          content={props.data.job_offer?.email_template_body}
          withVars
        />
      </div>
      <div className="flex justify-between text-sm text-[#8c8c8c] -mt-2">
        <div className="flex items-center gap-2">
          <InformationCircleIcon className="w-4 h-4 text-[#1890ff]" />
          <div className="info">Les messages InMail ont une limite de 1900 caractères.</div>
        </div>
        <div>Caractères : {emailTemplateLength}</div>
      </div>
    </div>
  );
});

export default RedactionStepForm;
