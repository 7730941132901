import { Checkbox, InputNumber, Radio, RangePicker, Select, TextInput } from "@getprorecrutement/getpro-design";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { StepHandle } from ".";
import { Project, ProjectData } from "../../../models/billing/project";
import { UUID } from "../../../models/common";
import { JobOffer, JobOfferData, JobOfferSalary, NewJobOffer } from "../../../models/jobOffers";
import { Skill, fetchSkills, getSuggestedSkills } from "../../../models/skill";
import { useFormManager } from "../../../services/form";
import { useDebounce } from "../../../utils";
import HelpTooltip from "../../helpTooltip";
import { JobTitleSelect } from "../inputs/JobTitleSelect";
import CustomerSelect from "../inputs/customerSelect";
import GeocodingSelect from "../inputs/geocodingSelect";
import UserSelect from "../inputs/userSelect";

interface Props {
  jobOffer: Partial<JobOffer>;
  isValid?: () => boolean;
  project: Partial<Project>;
  setDisabled: (value: boolean) => void;
  data:
    | NewJobOffer
    | {
        project?: ProjectData;
        job_offer?: JobOfferData;
      };
}

const optionsValidationPeriod = [
  { label: "Aucune", value: "0" },
  { label: "3 mois", value: "3" },
  { label: "4 mois", value: "4" },
  { label: "6 mois", value: "6" },
];

export const BasicStepForm = forwardRef<StepHandle, Props>(function BasicStepForm(props, ref) {
  const [categoryId, setCategoryId] = useState<UUID>();
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>();
  const [suggestedSkills, setSuggestedSkills] = useState<Skill[]>();
  const [skillInput, setSkillInput] = useState<string>("");
  const skillSearch = useDebounce(skillInput, 500);
  const [skills, setSkills] = useState<Skill[]>();
  const form = useFormManager<JobOffer>({
    defaultState: { experience: { min: 0, max: 20 }, ...props.data.job_offer } || {},
    validations: {
      name: {
        required: true,
        message: "Merci de préciser le nom de l'offre",
      },
      owner_id: {
        required: true,
        message: "Merci de préciser le propriétaire de l'offre",
      },
      company_id: {
        required: true,
        message: "Merci de préciser le client de l'offre",
      },
      location: {
        required: true,
        message: "Merci de préciser une localisation",
      },
      job_title_id: {
        required: true,
        message: "Merci de préciser le titre de poste de l'offre",
      },
      validation_period_months: {
        required: true,
        message: "Merci de préciser une période de validation",
      },
      salary: {
        required: (salary?: JobOfferSalary) => {
          if (salary && salary.min && salary.max && salary.min < salary.max) return true;
          else return false;
        },
        message: "Merci de préciser le salaire de l'offre",
      },
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      isValid() {
        return form.validate();
      },

      getData() {
        return {
          ...props.data,
          job_offer: { ...props.data.job_offer, ...form.state },
        };
      },
    }),
    [form.state]
  );

  useEffect(() => {
    props.setDisabled(form.disabled);
  }, [form.disabled]);

  useEffect(() => {
    if (props.jobOffer.full_skills) setSelectedSkills(props.jobOffer.full_skills);
    if (props.jobOffer.job_title) setCategoryId(props.jobOffer.job_title.category_id);
  }, [props.jobOffer]);

  useEffect(() => {
    if (categoryId) {
      getSuggestedSkills(categoryId, selectedSkills?.map((s) => s.id) || []).then(setSuggestedSkills);
    }
  }, [categoryId, selectedSkills]);

  useEffect(() => {
    if (skillSearch && skillSearch.length > 0) {
      fetchSkills({ name: skillSearch }).then(setSkills);
    } else {
      setSkills(undefined);
    }
  }, [skillSearch]);

  useEffect(() => {
    if (selectedSkills) {
      form.onChange({ skills: selectedSkills.map((s) => s.id) });
    }
  }, [selectedSkills]);

  return (
    <div className="flex flex-col gap-6 bg-inherit">
      <TextInput
        type="text"
        value={form.state.name}
        error={form.errors.name}
        onChange={(e) => form.onChange({ name: e.target.value })}
        light
        placeholder="Nom de l'offre"
        label="Nom de l'offre"
      />
      <GeocodingSelect
        error={form.errors.location}
        label="Localisation"
        value={form.state.location}
        onSelect={(v) => form.onChange({ location: v })}
      />
      <UserSelect
        label={"Propriétaire"}
        placeholder="Sélectionner le propriétaire de l'offre"
        value={form.state.owner_id}
        error={form.errors.owner_id}
        showSearch
        onSelect={(id: UUID) => form.onChange({ owner_id: id })}
      />
      <CustomerSelect
        label={"Entreprise"}
        value={form.state.company_id}
        error={form.errors.company_id}
        showSearch
        onSelect={(id: UUID) => form.onChange({ company_id: id })}
      />
      <div className="flex items-center gap-2">
        <div className="flex-1">
          <Checkbox
            value={!!form.state.anonymous_company}
            onChange={(e) => form.onChange({ anonymous_company: e })}
            light
            label="Entreprise anonyme"
          />
        </div>
        <div className="flex-1">
          <Checkbox
            light
            value={!!form.state.new_customer}
            onChange={(e) => form.onChange({ new_customer: e })}
            label="Nouveau client"
          />
        </div>
        <div className="flex-1">
          <Checkbox
            light
            value={!!form.state.exclude_from_stats}
            onChange={(e) => form.onChange({ exclude_from_stats: e })}
            label="Exclure des dashboards"
          />
        </div>
      </div>
      <div className="bg-inherit">
        <JobTitleSelect
          label="Titre de poste"
          error={form.errors.job_title_id}
          currentJobTitle={form.state.job_title}
          onChange={(jobTitle, categoryId) => {
            setCategoryId(categoryId);
            form.onChange({ job_title_id: jobTitle?.id, job_title: jobTitle });
          }}
        />
      </div>
      <div className="bg-inherit w-full">
        <Select
          rounded
          bordered
          onSearch={setSkillInput}
          label="Compétences"
          placeholder="Sélectionnez des compétences"
          disabled={suggestedSkills?.length === 0 && !selectedSkills}
          options={(selectedSkills || []).concat(
            skills?.filter((skill) => !selectedSkills?.find((selected) => selected.id === skill.id)) || []
          )}
          value={selectedSkills || []}
          customValueRender={(values: Skill[]) => (
            <div className="flex flex-wrap gap-2 items-center">
              {values.map((s) => (
                <div
                  className="border border-solid border-border-lighter text-content-darker rounded-full px-2 py-[1px] text-xs"
                  key={s.id}
                >
                  {s.name}
                </div>
              ))}
            </div>
          )}
          onChange={(skill) => {
            setSkillInput("");
            if (skill) {
              if (selectedSkills?.find((s) => s.id === skill.id)) {
                setSelectedSkills(selectedSkills?.filter((s) => s.id !== skill.id));
              } else {
                setSelectedSkills([...(selectedSkills || []), skill]);
              }
            }
          }}
          getKey={(s) => s.id}
          optionRender={(s) => s.name}
          type="multiple"
        />
        {suggestedSkills && suggestedSkills.length > 0 && (
          <div className="flex flex-wrap items-center gap-2 mt-2">
            <div className="text-sm text-content-light font-medium">Suggestions :</div>
            {suggestedSkills.map((s) => {
              return (
                <div
                  key={s.id}
                  className="cursor-pointer rounded-md border px-2 py-1 items-center bg-primary-bright text-primary-medium text-xs flex gap-1"
                  onClick={() => setSelectedSkills([...(selectedSkills || []), s])}
                >
                  {s.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex gap-4 items-center">
        <div className="whitespace-nowrap w-32">Expérience :</div>
        <div className="w-full pr-4">
          <RangePicker
            light
            prefix={(v) => (v == 20 ? "+" : "")}
            labelClassName="text-white font-bold"
            size={20}
            value={{ min: form.state.experience?.min || 0, max: form.state.experience?.max || 20 }}
            onChange={(v) => form.onChange({ experience: { ...v, max: v.max < 20 ? v.max : undefined } })}
          />
        </div>
      </div>
      <div className="flex gap-4 items-start   bg-inherit mt-6">
        <div className="whitespace-nowrap mt-2 w-32">Salaire :</div>
        <div className="flex items-start bg-inherit w-full">
          <div className="w-full bg-inherit">
            <InputNumber
              light
              inputClassName="rounded-r-none"
              placeholder="Salaire min"
              label=" min"
              controls={false}
              max={450000}
              display={{
                formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                parser: (value) => (value ? value?.replace(/[\s€]*/g, "") : ""),
              }}
              suffix="€"
              value={form.state.salary?.min}
              error={
                !form.state.salary?.min && form.errors.salary
                  ? form.errors.salary.replace("salaire", "salaire minimum")
                  : undefined
              }
              onChange={(e) => form.onChange({ salary: { max: form.state.salary?.max || 0, min: e || 0 } })}
            />
          </div>
          <div className="w-full bg-inherit">
            <InputNumber
              light
              controls={false}
              className="w-full"
              inputClassName="rounded-l-none"
              placeholder="Salaire max"
              max={500000}
              display={{
                formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                parser: (value) => (value ? value?.replace(/[\s€]*/g, "") : ""),
              }}
              suffix="€"
              label="max"
              value={form.state.salary?.max}
              error={
                !form.state.salary?.max && form.errors.salary
                  ? form.errors.salary.replace("salaire", "salaire maximum")
                  : form.errors.salary && form.state.salary?.max && form.state.salary.max <= form.state.salary.min
                  ? form.errors.salary.replace(
                      "le salaire de l'offre",
                      "un salaire maximum supérieur au salaire minimum pour l'offre"
                    )
                  : undefined
              }
              onChange={(e) => form.onChange({ salary: { min: form.state.salary?.min || 0, max: e || 0 } })}
            />
          </div>
        </div>
        <div className="mt-2">
          <HelpTooltip
            position="left"
            title={"Les informations renseignées dans ces champs seront visibles sur la job board"}
          />
        </div>
      </div>
      <InputNumber
        value={form.state.expected_salary}
        onChange={(e) => form.onChange({ expected_salary: e })}
        display={{
          formatter: (value) => value && value.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
          parser: (value) => (value ? value.replace(/[\s€]*/g, "") : ""),
        }}
        min={0}
        max={10000000}
        light
        placeholder="Salaire prévu"
        label="Salaire prévu"
        suffix="€"
        precision={2}
        controls={false}
      />
      <div className="flex gap-4 items-center">
        <div>Période de garantie :</div>
        <div>
          <Radio
            light
            optionType="button"
            options={optionsValidationPeriod}
            value={form.state.validation_period_months?.toString()}
            onChange={(v) => form.onChange({ validation_period_months: v ? parseInt(v) : undefined })}
          />
          {form.errors.validation_period_months && (
            <div className="text-error-normal italic">{form.errors.validation_period_months}</div>
          )}
        </div>
      </div>
    </div>
  );
});

export default BasicStepForm;
