import { Button, Empty, PopConfirm, Select, TextInput, Tooltip } from "@getprorecrutement/getpro-design";
import { BookmarkIcon, CheckIcon, LockClosedIcon, PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import { arrayMoveImmutable } from "array-move";
import React, { FunctionComponent, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import DashboardFilters from "../../components/dashboard/dashboardFilters";
import {
  BookingModule,
  CallStatusesModule,
  CandidatePipeModule,
  ContactedModule,
  RetainersModule,
  SourcingModule,
  StatusesModule,
  TerminatedModule,
} from "../../components/dashboard/modules";
import { DashboardModule, DatePeriod, GlobalDashboard, GlobalFilters, UserDashboard } from "../../models/dashboards";
import { Status } from "../../models/jobOffers";
import { getStatuses } from "../../services/requests";
import { randomString } from "../../utils";
import { getPeriod } from "../../utils/dates";

const DASHBOARD_MODULES: {
  [key: string]: React.FunctionComponent<{
    filters: GlobalFilters;
    locked: boolean;
    dashboardSlug: string;
    mod: DashboardModule<object>;
    statuses: Status[];
    updateModule: (data: {
      dashboard_slug: string;
      module_key: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filters: any;
      title?: string;
    }) => Promise<void>;
  }>;
} = {
  contacted: ContactedModule,
  statuses: StatusesModule,
  pipe: CandidatePipeModule,
  sourcing: SourcingModule,
  terminated: TerminatedModule,
  booking: BookingModule,
  retainers: RetainersModule,
  call_statuses: CallStatusesModule,
};

const DASHBOARD_LABELS = [
  { value: "contacted", label: "Candidats contactés" },
  { value: "statuses", label: "Suivi des statuts" },
  { value: "pipe", label: "Suivi des candidats" },
  { value: "sourcing", label: "Suivi du sourcing" },
  { value: "terminated", label: "Suivi des clôtures" },
  { value: "booking", label: "Suivi du CA" },
  { value: "retainers", label: "Suivi des acomptes sales" },
  { value: "call_statuses", label: "Suivi des cold calls" },
];

export const EXCLUDED_STATUSES = (kind: string) => [
  "cedcfdaf-1ee2-458e-b89c-8e19939af2d8",
  "94850f81-c820-47ab-b0d7-404912f1587c",
  // Remove contacted and incoming status for all modules except module statuses
  ...(kind !== "statuses" ? ["97df2814-b7b7-41ce-82a4-a6583ad64303", "67ba838b-3fc2-4f56-a1d7-7e82bda13816"] : []),
];

interface Props {
  dashboard: UserDashboard | GlobalDashboard;
  backLink: string;
  update: (d: UserDashboard | GlobalDashboard) => Promise<void>;
  remove: (id: string) => Promise<void>;
}

export const Dashboard: FunctionComponent<Props> = (props: Props) => {
  const [filters, _setFilters] = useState<GlobalFilters>(props.dashboard.filters || {});
  // const [tempDateRange, setTempDateRange] = useState<{ from?: string; to?: string }>();
  const [dashboardLocked, setDashboardLocked] = useState<boolean>(true);
  const [addModule, setAddModule] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const navigate = useNavigate();
  const [editTitle, setEditTitle] = useState<string>(props.dashboard?.name);
  const [dashboardModules, setDashboardModules] = useState<
    {
      render: () => JSX.Element;
      index: number;
      title?: string | undefined;
      kind: string;
      key: string;
      filters: object;
    }[]
  >([]);

  const { update, remove, dashboard } = props;

  const fetchStatuses = async () => {
    const res = await getStatuses();
    setStatuses(res.sort((a, b) => a.index - b.index));
  };

  useEffect(() => {
    setEditTitle(props.dashboard.name);
  }, [props.dashboard]);

  useEffect(() => {
    if (statuses.length > 0) setDashboardModules(getDashboardModules(filters));
  }, [dashboardLocked, dashboard.modules, statuses]);

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    initFilters();
  }, [dashboard.slug]);

  const moveModule = async ({
    source,
    destination,
  }: {
    source?: { index: number } | null;
    destination?: { index: number } | null;
  }) => {
    const currentModules = (dashboard?.modules || []).sort((a, b) => a.index - b.index);
    if (!currentModules) return;
    const fromIndex = currentModules.findIndex((d) => d.index === source?.index);
    const toIndex = currentModules.findIndex((d) => d.index === destination?.index);
    if (typeof fromIndex === "number" && typeof toIndex === "number" && currentModules) {
      const modules = arrayMoveImmutable(currentModules, fromIndex, toIndex);
      const newModules = modules.map((mod, i) => ({
        ...mod,
        index: i + 1,
      }));
      const payload = {
        ...dashboard,
        modules: newModules,
      } as UserDashboard;
      await update(payload);
    }
  };

  const completeAddModule = async (module_name?: string) => {
    const currentModules = (dashboard?.modules || []).sort((a, b) => a.index - b.index);
    if (module_name) {
      const mod: DashboardModule<object> = {
        index: 1,
        kind: module_name,
        title: DASHBOARD_LABELS.find((d) => d.value === module_name)?.label,
        key: `${module_name}-${randomString()}`,
        filters: {},
      };
      const modules = currentModules?.map((m, i) => {
        return {
          ...m,
          index: i + 2,
        };
      });
      const payload = {
        ...dashboard,
        modules: modules ? [...modules, mod] : [mod],
      };
      await update(payload);
      setAddModule(false);
    }
  };

  const removeModule = async (key: string) => {
    const currentModules = (dashboard?.modules || []).sort((a, b) => a.index - b.index);
    const mod = currentModules?.find((m) => m.key === key);
    if (mod) {
      const modules = currentModules
        ?.filter((e) => e.key !== key)
        .map((m) => {
          return m.index < mod.index
            ? m
            : {
                ...m,
                index: m.index - 1,
              };
        });
      const payload = {
        ...dashboard,
        modules: modules || [],
      };
      await update(payload);
    }
  };

  // Update filters locally
  const setFilters = async (rawFilters: GlobalFilters) => {
    const payload = rawFilters as GlobalFilters;

    if (rawFilters.period && !rawFilters.dateRange) payload.dateRange = getPeriod(rawFilters.period);

    _setFilters(payload);
    if (statuses.length > 0) setDashboardModules(getDashboardModules(payload));
  };

  // Update dashboard global filters
  const saveDashboardFilters = async () => {
    try {
      await update({ ...dashboard, filters: { ...filters, dateRange: undefined } });
      toast.success("Filtres globaux du dashboard mis à jour");
    } catch (e) {
      toast.error("Erreur lors de la sauvegarde des filtres globaux");
    }
  };

  // Set global filters at initilisation of dashboard
  const initFilters = () => {
    const filters = (dashboard.filters || {}) as GlobalFilters;
    if (!filters.period) filters.period = DatePeriod.Month;
    filters.dateRange = getPeriod(filters.period);
    _setFilters((filters || {}) as GlobalFilters);
    if (statuses.length > 0) setDashboardModules(getDashboardModules(filters));
  };

  const updateModule = async (data: {
    dashboard_slug: string;
    module_key: string;
    filters: {
      contact_types?: string[];
      show_offers?: boolean;
      granularity?: "day" | "week" | "month";
    };
    title?: string;
  }) => {
    const mod = (dashboard?.modules || []).find((m) => m.key === data.module_key);

    if (mod) {
      const newFilters = { ...mod.filters, ...data.filters };
      await update({
        ...dashboard,
        modules: [
          ...(dashboard?.modules || []).filter((m) => m.key !== data.module_key),
          {
            ...mod,
            filters: newFilters,
            title: data.title ? data.title : mod.title,
          },
        ],
      });
    }
  };

  const getDashboardModules = (filters: GlobalFilters) =>
    dashboard.slug
      ? (dashboard?.modules || []).map((m) => {
          const Module = DASHBOARD_MODULES[m.kind];
          return {
            ...m,
            render: () => (
              <div>
                {!dashboardLocked && (
                  <div className="top-0 right-1.5 absolute text-sm">
                    <PopConfirm
                      position="bottom-left"
                      title={"Êtes-vous sûr de vouloir supprimer ce module ?"}
                      onValidate={() => removeModule(m.key)}
                      light
                    >
                      <div className="text-red-400">Supprimer</div>
                    </PopConfirm>
                  </div>
                )}
                <Module
                  filters={filters}
                  locked={dashboardLocked}
                  dashboardSlug={dashboard.slug as string}
                  mod={m}
                  statuses={statuses.filter((status) => !EXCLUDED_STATUSES(m.kind).includes(status.id))}
                  updateModule={updateModule}
                />
              </div>
            ),
          };
        })
      : [];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="bg-white p-6 shadow-md mb-6 dashboardHeader">
        <div className="flex justify-between items-center mb-6 bg-inherit">
          {/* TODO CHECK USEDEEPCOMPARE RERENDER / TODO DELETE ALL DASHBOARDS ? */}
          {dashboardLocked ? (
            <div className="text-3xl font-bold">{dashboard.name}</div>
          ) : (
            <div className="flex items-center gap-2 bg-inherit">
              <TextInput
                light
                type="text"
                label="Nom du dashboard"
                placeholder="Nom du dashboard"
                value={editTitle}
                onChange={(e) => setEditTitle(e.currentTarget.value)}
              />
              <CheckIcon
                className="cursor-pointer"
                width={16}
                height={16}
                onClick={() =>
                  update({ ...dashboard, name: editTitle }).then(() =>
                    toast.success("Nom du dashboard mis à jour avec succès")
                  )
                }
              />
            </div>
          )}
          <div className="flex justify-end gap-3 bg-inherit">
            <Tooltip position="bottom" light title="Sauvegarder les filtres globaux">
              <Button
                light
                size="small"
                kind="outline"
                onClick={async () => await saveDashboardFilters()}
                icon={<BookmarkIcon />}
              />
            </Tooltip>
            <Tooltip position="bottom" light title={dashboardLocked ? "Editer les modules" : "Verrouiller les modules"}>
              <Button
                light
                size="small"
                kind="outline"
                icon={dashboardLocked ? <PencilIcon /> : <LockClosedIcon />}
                onClick={() => setDashboardLocked((locked) => !locked)}
              />
            </Tooltip>
            {!addModule && (
              <Button
                title="Ajouter un module"
                light
                style={{ width: 180 }}
                size="small"
                icon={<PlusIcon />}
                onClick={() => setAddModule(true)}
              />
            )}
            {addModule && (
              <Select
                light
                placeholder={"Sélectionner"}
                bordered
                rounded
                size="small"
                defaultOpen={true}
                style={{ width: 180 }}
                type="single"
                optionRender={(item) => item.label}
                getKey={(item) => item.value}
                options={DASHBOARD_LABELS}
                onChange={(e) => completeAddModule(e?.value)}
              />
            )}
            <PopConfirm
              title={"Êtes-vous sûr de vouloir supprimer ce dashboard ?"}
              onValidate={async () => {
                await remove((props.dashboard as GlobalDashboard).id || (props.dashboard as UserDashboard).slug);
                navigate(props.backLink);
              }}
              position="bottom"
              light
            >
              <Button className="text-red-400" size="small" title="Supprimer le dashboard" light kind="light" />
            </PopConfirm>
          </div>
        </div>
        <DashboardFilters
          users={filters.users}
          businessUnits={filters.businessUnits}
          jobOffers={filters.jobOffers}
          customers={filters.customers}
          period={filters.period}
          dateRange={filters.dateRange}
          onFilter={setFilters}
        />
      </div>
      <div className="mt-6">
        {(dashboard.modules || []).length > 0 ? (
          <DragDropContext onDragEnd={(props) => moveModule(props)}>
            <Droppable droppableId="dashboardDrop">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {dashboardModules
                    ?.sort((a, b) => a.index - b.index)
                    .map((module) => (
                      <Draggable
                        isDragDisabled={dashboardLocked}
                        key={module.key}
                        draggableId={module.key}
                        index={module.index}
                      >
                        {(provided) => (
                          <div
                            className="dashboardModule w-full relative"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {module.render()}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="bg-white p-6 shadow-md w-full">
            <Empty title={"Aucun module sur ce dashboard"} light />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
