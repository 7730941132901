import React, { FunctionComponent } from "react";
import { FullJobOffer, Status } from "../../models/jobOffers";
import { useState, useEffect } from "react";
import { CandidateSearchQuery } from "../../models/candidate";
import JobOfferSelect from "../../components/forms/inputs/jobOfferSelect";
import CustomerSelect from "../../components/forms/inputs/customerSelect";
import UserSelect from "../../components/forms/inputs/userSelect";
import { Checkbox, PopOver, RangePicker, Select, TextInput, Tooltip } from "@getprorecrutement/getpro-design";
import { BookmarkIcon, CheckIcon, InformationCircleIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Category, fetchJobTitleCategories } from "../../models/jobTitleCategory";
import { CategorySelect } from "../../components/forms/inputs/jobTitleCategorySelect/categorySelect";
import { Skill } from "../../models/skill";
import { UserResponse } from "../../models/users/users";
import { Customer } from "../../models/customers";
import SkillSelect from "../../components/forms/inputs/SkillsSelect";
import StatusSelect from "../../components/forms/inputs/statusSelect";
import CallStatusSelect from "../../components/forms/inputs/callStatusSelect";
import { searchQueryValid } from ".";
import JobTitleSelectMultiple from "../../components/forms/inputs/JobTitleSelect/JobTitleSelectMultiple";
import { JobTitle } from "../../models/jobTitle";
import { CitySelect } from "../../components/forms/inputs/cityGeocodingSelect";
import { NominatimLocationResponse, lookupLocation } from "../../models/common";

interface Props {
  query: CandidateSearchQuery;
  setQuery: (query: CandidateSearchQuery) => void;
  saveQuery: (name: string) => void;
  currentSavedQuery?: {
    name: string;
    path: string;
  };
  clearQuery: () => void;
}

export enum CandidateFilters {
  Name = "name",
  Email = "email",
  Phone = "phone",
  Statuses = "statuses",
  CallStatuses = "call_statuses",
  JobOffers = "job_offers",
  Users = "users",
  Customers = "customers",
  JobTitles = "job_titles",
  Categories = "categories",
  Experience = "experience",
  Skills = "skills",
  Location = "location",
  WithEmail = "with_email",
  WithPhone = "with_phone",
  WithConsentment = "with_consentment",
  WithNoProcess = "no_process",
}

const CANDIDATE_FILTERS = {
  [CandidateFilters.Name]: "Nom / Prénom",
  [CandidateFilters.Email]: "Adresse e-mail",
  [CandidateFilters.Phone]: "Téléphone",
  [CandidateFilters.Statuses]: "Statuts",
  [CandidateFilters.CallStatuses]: "Cold Calls",
  [CandidateFilters.JobOffers]: "Offres",
  [CandidateFilters.Users]: "Recruteurs",
  [CandidateFilters.Customers]: "Entreprises",
  [CandidateFilters.JobTitles]: "Titre de poste",
  [CandidateFilters.Categories]: "Catégories",
  [CandidateFilters.Experience]: "Expérience",
  [CandidateFilters.Skills]: "Compétences",
  [CandidateFilters.Location]: "Ville",
  [CandidateFilters.WithEmail]: "Avec email",
  [CandidateFilters.WithPhone]: "Avec téléphone",
  [CandidateFilters.WithConsentment]: "Avec RGPD",
  [CandidateFilters.WithNoProcess]: "Pas en process",
};

const jobOfferValueRender = customValueRender<FullJobOffer>(
  "name",
  CandidateFilters.JobOffers,
  2,
  "Candidats présents dans au moins une de ces offres"
);
const userValueRender = customValueRender<UserResponse>(
  "full_name",
  CandidateFilters.Users,
  3,
  "Candidats présents dans au moins une des offres de ces utilisateurs"
);
const customerValueRender = customValueRender<Customer>(
  "name",
  CandidateFilters.Customers,
  3,
  "Candidats présents dans au moins une des offres de ces clients"
);
const categoryValueRender = customValueRender<Category>(
  "title",
  CandidateFilters.Categories,
  3,
  "Candidats assignés à au moins une de ces catégories"
);
const skillValueRender = customValueRender<Skill>(
  "name",
  CandidateFilters.Skills,
  5,
  "Candidats possédant au moins une de ces compétences"
);
const statusValueRender = customValueRender<Status>(
  "name",
  CandidateFilters.Statuses,
  5,
  "Candidats possédant une candidature avec l'un de ces statuts"
);
const callStatusValueRender = customValueRender<Status>(
  "name",
  CandidateFilters.CallStatuses,
  5,
  "Candidats possédant une candidature avec l'un de ces statuts"
);
const jobTitleValueRender = customValueRender<JobTitle>(
  "title",
  CandidateFilters.JobTitles,
  2,
  "Candidats assignés à au moins un de ces titres de poste"
);

const locationValueRender = (value: JSX.Element, onClear: () => void, kind: CandidateFilters, hint?: string) => {
  return (
    <div className="flex items-center gap-2 flex-wrap">
      {hint && (
        <Tooltip title={hint}>
          <InformationCircleIcon width={16} height={16} className="text-content-regular" />
        </Tooltip>
      )}
      <div>{CANDIDATE_FILTERS[kind]} :</div>
      <div className="text-primary-medium font-bold flex">{value}</div>
      <XMarkIcon
        onClick={(e) => {
          e.stopPropagation();
          onClear();
        }}
        width={14}
        height={14}
        className="text-primary-medium stroke-2"
      />
    </div>
  );
};

function customValueRender<T>(key: keyof T, kind: CandidateFilters, maxElement?: number, hint?: string) {
  return function render(value: T[], onClear: () => void) {
    return (
      <div className="flex items-center gap-2 flex-wrap">
        {hint && (
          <Tooltip title={hint}>
            <InformationCircleIcon width={16} height={16} className="text-content-regular" />
          </Tooltip>
        )}
        <div>{CANDIDATE_FILTERS[kind]} :</div>
        {(maxElement ? value.slice(0, maxElement) : value).map((e, i) => (
          <div className="text-primary-medium font-bold flex" key={`${CANDIDATE_FILTERS[kind]}-${i}`}>
            {e[key]}
            {i < value.length - 1 ? "," : ""}
          </div>
        ))}
        {maxElement && value.length > maxElement && (
          <Tooltip
            position="bottom-left"
            customRenderer={() => (
              <div className="flex flex-col gap-1 text-primary-medium font-bold text-xs max-w-[250px]">
                {value.slice(maxElement).map((e, i) => (
                  <div className="truncate" key={`${CANDIDATE_FILTERS[kind]}-${maxElement + i}`}>
                    {e[key]}
                  </div>
                ))}
              </div>
            )}
          >
            <div className="text-primary-medium font-bold">+{value.length - maxElement}</div>
          </Tooltip>
        )}
        {value.length > 0 && (
          <XMarkIcon
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
            width={14}
            height={14}
            className="text-primary-medium stroke-2"
          />
        )}
      </div>
    );
  };
}

const CustomTextInput = ({
  input,
  value,
  onUpdate,
}: {
  input: CandidateFilters;
  value: string | undefined;
  onUpdate: (val?: string) => void;
}) => {
  return (
    <div className="flex items-center gap-2 border border-solid border-border-lighter rounded-full hover:border-border-darker px-4">
      <div className="text-xs text-content-darker font-medium">{CANDIDATE_FILTERS[input]} :</div>
      <TextInput
        light
        inputClassName="px-0 text-primary-medium font-bold"
        bordered={false}
        size="small"
        type="text"
        placeholder={CANDIDATE_FILTERS[input]}
        value={value}
        onChange={(e) => onUpdate(e.currentTarget.value || undefined)}
      />
      <XMarkIcon
        onClick={(e) => {
          e.stopPropagation();
          onUpdate(undefined);
        }}
        width={14}
        height={14}
        className={`text-primary-medium stroke-2 cursor-pointer ${value && value.length ? "visible" : "invisible"}`}
      />
    </div>
  );
};

export const CandidatesFilter: FunctionComponent<Props> = ({
  query,
  setQuery: _setQuery,
  saveQuery: _saveQuery,
  currentSavedQuery,
  clearQuery,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<NominatimLocationResponse>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [savingQuery, setSavingQuery] = useState<string>();

  const setQuery = (query: CandidateSearchQuery) => {
    _setQuery(query);
    if (savingQuery !== undefined) setSavingQuery(undefined);
  };

  const saveQuery = async (name: string) => {
    await _saveQuery(name);
    setSavingQuery(undefined);
  };

  useEffect(() => {
    fetchJobTitleCategories().then(setCategories);
  }, []);

  useEffect(() => {
    if (query.location && !selectedLocation)
      lookupLocation(query.location.location_id).then((l) => setSelectedLocation(l));
    else if (!query.location && selectedLocation) setSelectedLocation(undefined);
  }, [query.location]);

  useEffect(() => {
    if (selectedLocation && query.location?.location_id !== selectedLocation.location_id)
      setQuery({
        location: {
          location_id: selectedLocation.location_id,
          lat: selectedLocation.lat,
          lon: selectedLocation.lon,
        },
      });
  }, [selectedLocation]);

  useEffect(() => {
    setSelectedCategories(
      query.categories && categories ? categories.filter((c) => query.categories?.includes(c.id)) : []
    );
  }, [query, categories]);

  const FILTER_INPUTS = {
    // Rename search with name
    [CandidateFilters.Name]: (
      <CustomTextInput input={CandidateFilters.Name} value={query.name} onUpdate={(v) => setQuery({ name: v })} />
    ),
    [CandidateFilters.Email]: (
      <CustomTextInput input={CandidateFilters.Email} value={query.email} onUpdate={(v) => setQuery({ email: v })} />
    ),
    [CandidateFilters.Phone]: (
      <CustomTextInput input={CandidateFilters.Phone} value={query.phone} onUpdate={(v) => setQuery({ phone: v })} />
    ),
    [CandidateFilters.Statuses]: (
      <StatusSelect
        multiple
        size="small"
        dropdownClassName="min-w-[250px]"
        placeholder={CANDIDATE_FILTERS[CandidateFilters.Statuses]}
        value={query?.statuses}
        onSelect={(id) => onSelect(id, "statuses")}
        customValueRender={(v) => statusValueRender(v, () => setQuery({ statuses: undefined }))}
      />
    ),
    [CandidateFilters.CallStatuses]: (
      <CallStatusSelect
        multiple
        size="small"
        showArrowIcon
        dropdownClassName="min-w-[250px]"
        placeholder={CANDIDATE_FILTERS[CandidateFilters.CallStatuses]}
        value={query?.call_statuses}
        onSelect={(id) => onSelect(id, "call_statuses")}
        customValueRender={(v) => callStatusValueRender(v, () => setQuery({ call_statuses: undefined }))}
      />
    ),
    [CandidateFilters.JobOffers]: (
      <div className="flex items-center gap-2">
        <JobOfferSelect
          multiple
          size="small"
          dropdownClassName="min-w-[250px]"
          showArrowIcon
          placeholder={CANDIDATE_FILTERS[CandidateFilters.JobOffers]}
          includeArchived={true}
          value={query?.job_offers || []}
          onSelect={(id) => onSelect(id, CandidateFilters.JobOffers)}
          customValueRender={(v) => jobOfferValueRender(v, () => setQuery({ job_offers: undefined }))}
        />
        {query.job_offers?.length ? (
          <Select
            type="single"
            options={["Toutes", "Actives", "Cloturées"]}
            value={
              query.include_terminated !== undefined
                ? query.include_terminated === true
                  ? "Cloturées"
                  : "Actives"
                : "Toutes"
            }
            bordered={true}
            size="small"
            getKey={(e) => e}
            optionRender={(e) => e}
            onChange={(e) =>
              e && setQuery({ include_terminated: e === "Toutes" ? undefined : e === "Cloturées" ? true : false })
            }
            customValueRender={(v) => (
              <div className="flex items-center gap-2">
                <div>État des candidatures :</div>
                <div className="text-primary-medium font-bold">{v}</div>
              </div>
            )}
          />
        ) : undefined}
      </div>
    ),
    [CandidateFilters.Users]: (
      <UserSelect
        multiple
        size="small"
        dropdownClassName="min-w-[250px]"
        showArrowIcon
        showSearch
        showIcon={false}
        placeholder={CANDIDATE_FILTERS[CandidateFilters.Users]}
        value={query?.users}
        onSelect={(id) => onSelect(id, CandidateFilters.Users)}
        customValueRender={(v) => userValueRender(v, () => setQuery({ users: undefined }))}
      />
    ),
    [CandidateFilters.Customers]: (
      <CustomerSelect
        multiple
        size="small"
        showArrowIcon
        placeholder={CANDIDATE_FILTERS[CandidateFilters.Customers]}
        value={query?.customers}
        showSearch
        onSelect={(id) => onSelect(id, CandidateFilters.Customers)}
        customValueRender={(v) => customerValueRender(v, () => setQuery({ customers: undefined }))}
        dropdownClassName="min-w-[250px]"
      />
    ),
    [CandidateFilters.JobTitles]: (
      <JobTitleSelectMultiple
        value={query?.job_titles}
        showSearch
        showArrowIcon
        size="small"
        dropdownClassName="min-w-[250px]"
        customValueRender={(v) => jobTitleValueRender(v, () => setQuery({ job_titles: undefined }))}
        onSelect={(id) => onSelect(id, CandidateFilters.JobTitles)}
        placeholder={CANDIDATE_FILTERS[CandidateFilters.JobTitles]}
      />
    ),
    [CandidateFilters.Categories]: (
      <CategorySelect
        dropdownClassName="min-w-[250px]"
        multiple
        size="small"
        categories={categories}
        value={selectedCategories}
        showArrowIcon
        placeholder={CANDIDATE_FILTERS[CandidateFilters.Categories]}
        customValueRender={(v) => categoryValueRender(v, () => setQuery({ categories: undefined }))}
        onSelect={(cat) => cat && onSelect(cat.id, CandidateFilters.Categories)}
      />
    ),
    [CandidateFilters.Skills]: (
      <SkillSelect
        value={query.skills}
        showSearch
        showArrowIcon
        size="small"
        dropdownClassName="min-w-[250px]"
        multiple
        customValueRender={(v) => skillValueRender(v, () => setQuery({ skills: undefined }))}
        onSelect={(id) => onSelect(id, CandidateFilters.Skills)}
        placeholder={CANDIDATE_FILTERS[CandidateFilters.Skills]}
      />
    ),
    [CandidateFilters.Location]: (
      <CitySelect
        size="small"
        hideRadius
        value={selectedLocation}
        onChange={setSelectedLocation}
        customValueRender={(v) =>
          locationValueRender(
            v,
            () => setQuery({ location: undefined }),
            CandidateFilters.Location,
            "Candidats disponible à cette localisation ou en full remote"
          )
        }
      />
    ),
    [CandidateFilters.WithEmail]: (
      <div className="py-[7px] px-4 border border-solid border-border-lighter rounded-full text-primary-medium font-bold text-xs">
        {CANDIDATE_FILTERS[CandidateFilters.WithEmail]}
      </div>
    ),
    [CandidateFilters.WithPhone]: (
      <div className="py-[7px] px-4 border border-solid border-border-lighter rounded-full text-primary-medium font-bold text-xs">
        {CANDIDATE_FILTERS[CandidateFilters.WithPhone]}
      </div>
    ),
    [CandidateFilters.WithConsentment]: (
      <div className="py-[7px] px-4 border border-solid border-border-lighter rounded-full text-primary-medium font-bold text-xs">
        {CANDIDATE_FILTERS[CandidateFilters.WithConsentment]}
      </div>
    ),
    [CandidateFilters.WithNoProcess]: (
      <div className="py-[7px] px-4 border border-solid border-border-lighter rounded-full text-primary-medium font-bold text-xs">
        {CANDIDATE_FILTERS[CandidateFilters.WithNoProcess]}
      </div>
    ),
    [CandidateFilters.Experience]: (
      <PopOver
        position="bottom-right"
        content={
          <div className="w-[250px]">
            <div className="text-xs font-medium">Années d'expériences dans le métier :</div>
            <div className="mt-4 mb-8">
              <RangePicker
                suffix=""
                size={30}
                value={query.experience || { min: 0, max: 30 }}
                onChange={(v) => setQuery({ experience: { min: v.min, max: v.max } })}
              />
            </div>
          </div>
        }
      >
        <div className="px-4 py-[7px] text-xs flex items-center gap-2 border border-solid border-border-lighter rounded-full hover:border-border-darker">
          <div className="text-content-darker font-medium">{CANDIDATE_FILTERS[CandidateFilters.Experience]} :</div>
          <div className="text-primary-medium font-bold">
            {query.experience?.min || 0} - {query.experience?.max || 30} ans
          </div>
        </div>
      </PopOver>
    ),
  };

  const onSelect = (
    id: string,
    key: "job_offers" | "users" | "customers" | "skills" | "statuses" | "call_statuses" | "categories" | "job_titles"
  ) => {
    (query[key] || []).includes(id)
      ? setQuery({
        [key]: (query[key] || []).filter((sId) => sId !== id),
      })
      : setQuery({
        [key]: [...(query[key] || []), id],
      });
  };

  const selectFilter = (key: CandidateFilters, value: boolean) => {
    setQuery({
      selected_filters: value
        ? [...(query.selected_filters || []), key]
        : query.selected_filters?.filter((e) => e !== key),
      // Define default value when necessary
      [key]:
        value &&
          [
            CandidateFilters.WithEmail,
            CandidateFilters.WithPhone,
            CandidateFilters.WithConsentment,
            CandidateFilters.WithNoProcess,
          ].includes(key)
          ? true
          : value && key === CandidateFilters.Experience
            ? { min: 0, max: 30 }
            : undefined,
      page: query.page,
      per_page: query.per_page,
    });
  };

  return (
    <div className="candidatesFilter bg-inherit">
      <div className="flex items-center gap-4 flex-wrap mt-4">
        <PopOver
          className="w-fit"
          position="bottom-right"
          content={
            <div>
              {Object.entries(CANDIDATE_FILTERS).map(([key, value]) => (
                <Checkbox
                  className="whitespace-nowrap text-sm"
                  key={key}
                  label={value}
                  value={query.selected_filters?.includes(key as CandidateFilters) || false}
                  onChange={(v) => selectFilter(key as CandidateFilters, v)}
                />
              ))}
            </div>
          }
        >
          <div className="bg-content-darker text-xs text-white rounded-full flex items-center gap-2.5 w-fit px-4 py-2">
            <PlusIcon className="w-4 h-4 stroke-2" />
            <div className="font-medium">Filtres</div>
          </div>
        </PopOver>
        <div className="h-6 w-[1px] bg-black" />
        {currentSavedQuery ? (
          <div className="flex items-center gap-2 text-xs">
            <div>Ma recherche:</div>
            <div className="text-primary-regular font-bold">{currentSavedQuery.name}</div>
            <XMarkIcon
              width={16}
              height={16}
              className="text-primary-regular stroke-2 cursor-pointer"
              onClick={clearQuery}
            />
          </div>
        ) : savingQuery === undefined ? (
          <div
            onClick={() => searchQueryValid(query) && setSavingQuery("")}
            className={`flex gap-2 items-center text-xs font-medium ${searchQueryValid(query) ? "cursor-pointer" : "text-content-light"
              }`}
          >
            <BookmarkIcon width={16} height={16} />
            <div>Enregistrer la recherche</div>
          </div>
        ) : (
          <div className="bg-background-lightest flex items-center gap-2 py-2 px-4 rounded-full">
            <TextInput
              light
              inputClassName="p-0"
              bordered={false}
              size="small"
              type="text"
              onKeyUp={async (ev) => ev.key === "Enter" && savingQuery.length > 0 && (await saveQuery(savingQuery))}
              placeholder={"Nom de la recherche"}
              value={savingQuery}
              onChange={(e) => setSavingQuery(e.currentTarget.value)}
            />
            {savingQuery.length > 0 && (
              <div
                className="bg-content-darker rounded-full w-4 h-4 flex items-center justify-center cursor-pointer"
                onClick={async () => await saveQuery(savingQuery)}
              >
                <CheckIcon width={12} height={12} className="text-content-bright stroke-2" />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2 mt-4 flex-wrap">
        {(query.selected_filters || []).map((kind) => (
          <div className="w-fit" key={kind}>
            {FILTER_INPUTS[kind]}
          </div>
        ))}
      </div>
      {/* <Divider title="Recherche avancée" light className="mb-4" />
      <div className="flex justify-start w-full bg-inherit gap-4 flex-wrap">
        <div className="bg-inherit flex flex-col gap-4 w-[32%]">
          <JobOfferSelect
            label="Offres"
            placeholder="Rechercher par offre"
            includeArchived={query.include_archived}
            multiple
            value={query?.job_offers || []}
            onSelect={(id) => onSelect(id, "job_offers")}
          />
          <CategoriesSelect onSelect={(id) => onSelect(id, "categories")} value={query.categories} />
        </div>
        <div className="bg-inherit flex flex-col gap-4 w-[32%]">
          <StatusSelect
            multiple
            label="Statuts"
            placeholder="Rechercher par statut de candidature"
            statuses={allStatuses}
            value={query?.statuses}
            onSelect={(id) => onSelect(id, "statuses")}
          />
          <CustomerSelect
            multiple
            label={"Entreprises"}
            placeholder="Rechercher par entreprise"
            value={query.customers}
            showSearch
            onSelect={(id) => onSelect(id, "customers")}
          />
        </div>
        <div className="bg-inherit flex flex-col gap-4 w-[32%]">
          <CallStatusSelect
            multiple
            label="Cold Calls"
            placeholder="Rechercher par call statut de candidature"
            callStatuses={allCallStatuses}
            value={query?.call_statuses}
            onSelect={(id) => onSelect(id, "call_statuses")}
          />
          <UserSelect
            multiple
            showSearch
            label={"Utilisateurs"}
            placeholder="Rechercher par propriétaire d'offre"
            value={query.users}
            onSelect={(id) => onSelect(id, "users")}
          />
        </div>
      </div>

      <Divider title="Filtres additionnels" light />
      <div className="flex items-center justify-between">
        <Checkbox
          label="Avec adresse e-mail"
          light
          value={!!query.with_email}
          onChange={(v) => setQuery({ with_email: v || undefined })}
        />
        <Checkbox
          label="Avec téléphone"
          light
          value={!!query.with_phone}
          onChange={(v) => setQuery({ with_phone: v || undefined })}
        />
        <Checkbox
          label="Inclure les candidats d'offres archivées"
          light
          value={!!query.include_archived}
          onChange={(v) => setQuery({ include_archived: v || undefined })}
        />
        {query.job_offers && query.job_offers.length > 0 && (
          <div className="flex items-center">
            <div className="mr-4">Inclure les candidatures</div>
            <Radio
              light
              optionType="button"
              options={[
                { label: "Toutes", value: "undefined" },
                { label: "Actives", value: "false" },
                { label: "Cloturées", value: "true" },
              ]}
              value={
                query.include_terminated !== undefined
                  ? query.include_terminated === true
                    ? "true"
                    : "false"
                  : "undefined"
              }
              onChange={(val) => setQuery({ include_terminated: val !== "undefined" ? val === "true" : undefined })}
            />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default CandidatesFilter;
